import React, { useRef, useState } from "react";
import classes from "./GalleryGrid.module.css";
import { Image, Modal } from "antd";
import RightLongArrow from "components/UI/icons/RightLongArrow";
import useWindowWidth from "helpers/useWindowWidth";
import ReactPlayer from "react-player";
import PlayButton from "components/UI/icons/PlayButton";
import CloseIcon from "components/UI/icons/CloseIcon";
import { useNavigate } from "react-router-dom";

const GalleryGrid = ({ images, videos, type = "photo", venueId }) => {
  let imagesSorted;
  let videosSorted;
  const navigate = useNavigate();
  const width = useWindowWidth();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [playing, setPlaying] = useState(true);

  const playerRef = useRef(null);

  const handleUnmountVideo = () => {
    if (playerRef.current) {
      playerRef.current.unmount();
    }
  };

  //to filter no. of items to show according to screen size
  if (width > 1200) {
    imagesSorted = images?.slice(0, 8);
    videosSorted = videos?.slice(0, 8);
  } else if (width > 767) {
    imagesSorted = images?.slice(0, 6);
    videosSorted = videos?.slice(0, 6);
  } else {
    imagesSorted = images?.slice(0, 4);
    videosSorted = videos?.slice(0, 4);
  }

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setModalOpen(true);
    setPlaying(true);
  };

  const handleVideoClose = () => {
    setModalOpen(false);
    setPlaying(false);
  };

  const getVideoThumbnail = async (url) => {
    const player = new ReactPlayer({ url });
    return player.getThumbnail();
  };

  const navigateToGallery = () => {
    navigate(`/venue/${venueId}/gallery`);
  };

  const getVideoId = (videoUrl) => {
    // Regular expression to extract the video ID from the YouTube URL
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    // Extracting video ID using regular expression match
    const match = videoUrl.match(regex);
    const videoId = match && match[1]; // Extracted video ID
    console.log("video id ", videoId);
    return videoId;
  };

  return (
    <div className={classes.galleryContainer}>
      <div className={classes.galleryGrid}>
        {type === "photo" && (
          <Image.PreviewGroup>
            {imagesSorted?.map((cur, i) => (
              // used same classname to correct border radius of preview mask
              <div className="venueImage">
                <Image className="venueImage" key={i} src={cur} />
              </div>
            ))}
          </Image.PreviewGroup>
        )}
        {type === "video" && (
          <>
            {videosSorted?.map((cur, i) => (
              <div
                className={classes.venueImage}
                onClick={() => handleVideoClick(cur)}
              >
                <img
                  // className="lazy"
                  src={`https://img.youtube.com/vi/${getVideoId(
                    cur.link
                  )}/0.jpg`}
                  // src={`https://img.youtube.com/vi/${cur.videoId}/0.jpg`}
                  alt={cur.videoId}
                  className={classes.thumbnail}
                />
                <PlayButton className={classes.playButton} />
              </div>
            ))}
          </>
        )}
      </div>
      <div className={classes.viewAll} onClick={navigateToGallery}>
        View All
        <RightLongArrow className={classes.rightArrow} />
      </div>
      {type === "video" && (
        <Modal
          title={selectedVideo?.title}
          // centered
          open={modalOpen}
          onCancel={handleVideoClose}
          className={classes.videoModal}
          destroyOnClose //to stop play after closing
          footer={null}
          closeIcon={<CloseIcon />}
          // width={"55vw"}
          // bodyStyle={{ height: "50vh", overflow: "auto" }}
          // height={"800px"}
          // style={{ height: "400px" }}
          // style={{ overflowY: "auto" }}
        >
          <div className={classes.modalBody}>
            {playing && (
              <ReactPlayer
                url={selectedVideo?.link}
                // url={selectedVideo?.videoUrl}
                // ref={playerRef}
                playing={playing}
                controls
                width="100%"
                height="100%"
                stopOnUnmount
              />
            )}
            {/* <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${selectedVideo?.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={selectedVideo?.title}
            /> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default GalleryGrid;

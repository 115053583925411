import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import classes from "./AdditionalDateCalendar.module.css";
import Button from "components/UI/button/Button";
import { Form, Modal, notification } from "antd";
import CloseIcon from "components/UI/icons/CloseIcon";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import RemoveCircleIcon from "components/UI/icons/RemoveCircleIcon";
import { fixDecimal } from "helpers/fixDecimal";
// import 'react-calendar/dist/Calendar.css';
//----css for react-calender is taken from node modules and are changed in index.css-----

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date?.getFullYear();
  const month = date?.getMonth(); // Month is 0-indexed (0 = January, 1 = February, etc.)
  const day = date?.getDate();

  // Create the desired format (year, month, day) - with month as 0-indexed
  const formattedDate = `${year}, ${month}, ${day}`;
  return formattedDate;
};

const AdditionalDateCalendar = ({
  details,
  additionalDates,
  setAdditionalDates,
  // setSelectedDate,
  selectedDate,
  selectedInterval,
  //   index,
  //   editMode,
  //   addAdditionalDate,
  removeDate,
  //   remarkChange,
  //   selectedIntervalChange,
}) => {
  // const currentDate = formatDate(additionalDates[index]?.eventDate);
  const [dateModal, setDateModal] = useState(false);
  const [dateForm] = Form.useForm();
  const [modalAdditionalDates, setModalAdditionalDates] = useState(
    additionalDates?.length > 0
      ? [
          ...additionalDates,
          { eventDate: "", start: "", end: "", slotNo: "", remarks: "" },
        ]
      : [{ eventDate: "", start: "", end: "", slotNo: "", remarks: "" }]
  );
  const [selectedAdditionalDateIntervals, setSelectedAdditionalDateIntervals] =
    useState([]);
  const [modalSelectedInterval, setModalSelectedInterval] = useState(null);
  console.log("555 additionalDates", additionalDates);
  console.log("555 modalAdditionalDates", modalAdditionalDates);
  console.log(
    "555 selectedAdditionalDateIntervals",
    selectedAdditionalDateIntervals
  );
  console.log("rrr selected date", selectedDate);

  // console.log("current date is", currentDate);
  const [value, onChange] = useState(
    new Date()
    // additionalDates[index]?.eventDate
    //   ? new Date(additionalDates[index]?.eventDate)
    //   : new Date()
  );
  // console.log("value of date", value);

  useEffect(() => {
    if (dateModal) {
      setModalAdditionalDates(
        additionalDates?.length > 0
          ? [
              ...additionalDates,
              { eventDate: "", start: "", end: "", slotNo: "", remarks: "" },
            ]
          : [{ eventDate: "", start: "", end: "", slotNo: "", remarks: "" }]
      );
    }
  }, [dateModal]);

  useEffect(() => {
    if (value) {
      // setDayValue(days[value.getDay()]);

      //   const updated = additionalDates?.filter(
      //     (item, indexvalue) => indexvalue == index
      //   );

      const year = value.getFullYear();
      const month = String(value.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const day = String(value.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      const isCustomDate = details?.customPriceDates?.filter(
        (item) => item.date == formattedDate
      );
      console.log("is custom price", isCustomDate, formattedDate);
      let intervalsValue;
      if (isCustomDate?.length == 0) {
        intervalsValue = details?.availableDates?.find(
          (item) => item?.date == formattedDate
        )?.intervals;

        // console.log("intervals aree 1", intervalsValue, formattedDate);
      } else {
        const custompriceValue = details?.customPriceDates?.find(
          (item) => item?.date == formattedDate
        )?.price;
        console.log("custom price value", custompriceValue);
        const newinterval = details?.availableDates?.find(
          (item) => item?.date == formattedDate
        )?.intervals;
        intervalsValue = newinterval?.map((item) => ({
          ...item,
          price: custompriceValue,
        }));
      }
      console.log(
        "555 intervals aree",
        intervalsValue,
        formattedDate,
        isCustomDate
      );

      setSelectedAdditionalDateIntervals(intervalsValue);

      setModalAdditionalDates(
        modalAdditionalDates?.map((item, indexvalue) => {
          if (indexvalue == modalAdditionalDates?.length - 1) {
            return {
              ...item,
              eventDate: formattedDate,
              // intervals: intervalsValue,
              // selectedInterval: {},
              // remarks: "",
            };
          } else return item;
        })
      );

      // setSelectedDate(formattedDate);
    }
  }, [value]);

  // Function to render content in date tile
  const tileContent = ({ date, view }) => {
    const priceObject = {};

    // const resultArray = selectedListing.customPriceDates.filter(
    //   (item) => !selectedListing.removedDates.includes(item.date)
    // );

    // const resultArray2 = resultArray?.filter(itemA =>
    //     selectedListing?.availableDates.some(itemB => itemB.date === itemA.date)
    // );

    const resArray = details?.availableDates.filter(
      (item) => !details.removedDates.includes(item.date)
    );

    const notInBooked = resArray.filter(
      (item) =>
        !details.bookedDates.some(
          (data) =>
            data.date == item.date &&
            details?.availableDates?.find((data) => data?.date == item)
              ?.intervals?.length == 0
        ) //explanation added in other calendar component
    );

    // console.log('res array ttt', notInBooked)

    notInBooked?.forEach((item) => {
      priceObject[item.date] = item.intervals[0].price;
    });

    // selectedListing?.customPriceDates.forEach(item => {
    //   if (priceObject[item.date] !== undefined) {
    //     priceObject[item.date] = item.price; // Convert price to string if necessary
    //   } else {
    //     // console.log(`Date ${item.date} not found in prices object.`);
    //   }
    // });

    Object.keys(priceObject).forEach((item) => {
      const dateToCheck = new Date(item);

      details?.customPrice.forEach((customPrice) => {
        const fromDate = new Date(customPrice?.fromDate);
        const toDate = new Date(customPrice?.toDate);

        if (dateToCheck >= fromDate && dateToCheck <= toDate) {
          priceObject[item] = customPrice.price;
        }
      });
    });

    // console.log("price object is", priceObject);
    // Example: Add price to specific dates
    // const prices = {
    //   "2024-03-17": "1030000",
    //   "2024-03-20": "1550000",
    //   "2024-03-21": "1500000",
    // };

    // const price = prices[date.toISOString().slice(0, 10)];  //using this creates error of timezone
    const price =
      priceObject[
        new Date(date.getTime() - date.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ]; //accomodating time zone differnece
    // console.log("date ---", date.toISOString().slice(0, 10));
    // console.log("prices ---", price);

    if (price && view === "month") {
      //price converted in global stds
      // if (price >= 1000000) {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{(Number(price) / 1000000).toFixed(1).replace(/\.?0*$/, '') + 'M'}</p>;
      // } else if (price >= 1000) {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{(Number(price) / 1000).toFixed(1).replace(/\.?0*$/, '') + 'K'}</p>;
      // } else {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{Number(price).toString()}</p>;
      // }

      //price converted in Indian stds
      if (price >= 10000000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹
            {(Number(price) / 10000000).toFixed(1).replace(/\.?0*$/, "") + "Cr"}
          </p>
        );
        // return (value / 10000000).toFixed(1) + ' Cr';
      } else if (price >= 100000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹{(Number(price) / 100000).toFixed(1).replace(/\.?0*$/, "") + "L"}
          </p>
        );
        // return (value / 100000).toFixed(1) + ' L';
      } else if (price >= 1000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            {/* ₹{(Number(price) / 1000).toFixed(1).replace(/\.?0*$/, "") + "T"} */}
            ₹{Number(price)?.toLocaleString("en-IN")}
          </p>
        );
        // return (value / 1000).toFixed(1) + ' T';
      } else {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹{Number(price).toString()}
          </p>
        );
        // return value.toString();
      }
      // return <p>{price}</p>;
    }
    return <h6 style={{ margin: "0", color: "transparent" }}>--</h6>;
  };

  // const tileDisabled = ({ date }) => {
  //     // Define an array of specific dates to disable in 'YYYY-MM-DD' format
  //     const disabledDates = [
  //         '2024-03-15',
  //         '2024-03-20',
  //     ];

  //     // Convert the date to 'YYYY-MM-DD' format for comparison
  //     // const formattedDate = date.toISOString().slice(0, 10); //using this creates error of timezone
  //     const formattedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10); //accomodating time zone differnece

  //     return disabledDates.includes(formattedDate);
  // };

  // console.log("date vf", selectedInterval);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const tileDisabled = ({ date }) => {
    // Define an array of specific dates to disable in 'YYYY-MM-DD' format

    const disabled = details?.availableDates?.map((item) => item.date);
    const disabledFinal = disabled?.filter(
      (item) => !details?.removedDates.includes(item)
    );

    const removeSelectedDate = disabledFinal?.filter((item) => {
      let intervalValue;
      if (item == selectedDate) {
        //check if this date already selected in main datepicking calendar
        intervalValue = details?.availableDates?.find(
          //find any interval pending in this date
          (card) => card?.date == selectedDate
        )?.intervals;
        // console.log("ixx fetched", intervalValue);
        if (intervalValue?.length > 0) {
          if (
            intervalValue?.length == 1 &&
            intervalValue[0]?.slotNo != selectedInterval?.slotNo
          ) {
            //if only one interval pending and that is got selected in selected interval,it shouldnt show
            return item;
          }
          if (intervalValue?.length > 1) {
            return item;
          }
        }
      } else {
        return item;
      }
    });

    const removeFromMultiple = removeSelectedDate?.filter((item) => {
      const intervals = details?.availableDates?.find(
        (data) => data?.date == item
      )?.intervals;
      const additionalCommonDateIntervals = additionalDates?.filter(
        (card) => card?.eventDate == item && card?.slotNo != ""
      );
      if (intervals?.length == additionalCommonDateIntervals?.length) {
        // console.error('failed')
      } else {
        return item;
      }

      //   const allSlotsExist = intervals.every(interval =>
      //     additionalCommonDateIntervals.some(common =>
      //         common.slotNo === interval.slotNo && common.eventDate === interval.date
      //     )
      // );

      // if(!allSlotsExist){
      //   return item
      // }

      //   const allSlotsExist = intervals.every(interval =>
      //     additionalDates.some(additional =>
      //         additional.slotNo === interval.slotNo && additional.eventDate === interval.date
      //     )
      // );
      // if(!allSlotsExist){
      //   return item
      // }
    });

    const notInBooked = disabledFinal.filter(
      (item) =>
        !details.bookedDates.some(
          (data) =>
            data.date == item &&
            details?.availableDates?.find((data) => data?.date == item)
              ?.intervals?.length == 0
        )
    );

    const dateToShow = notInBooked.filter((date) => {
      const dateObj = new Date(date);
      return dateObj >= yesterday;
    });

    // console.log("disabled date values", disabledFinal);

    const disabledDates = ["2024-03-15", "2024-03-20"];

    // Convert the date to 'YYYY-MM-DD' format for comparison
    // const formattedDate = date.toISOString().slice(0, 10); //using this creates error of timezone
    const formattedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10); //accomodating time zone differnece

    return !dateToShow?.includes(formattedDate);
  };

  //additional date functions
  //   const addAdditionalDate = () => {
  //     setAdditionalDates([
  //       ...additionalDates,
  //       //   { eventDate: "", intervals: [], selectedinterval: {}, remarks: "" },
  //       { eventDate: "", start: "", end: "", slotNo: "", remarks: "" },
  //     ]);
  //   };

  //   const removeDate = (index) => {
  //     setAdditionalDates(
  //       additionalDates?.filter((item, indexvalue) => indexvalue != index)
  //     );
  //   };

  const remarkChange = (e, index) => {
    setModalAdditionalDates(
      modalAdditionalDates?.map((data, indexValue) => {
        if (indexValue == modalAdditionalDates?.length - 1) {
          return { ...data, remarks: e.target.value };
        } else {
          return data;
        }
      })
    );
  };

  const selectedIntervalChange = (index, interval) => {
    console.log(" 555 interval selecteddddd", interval);
    setModalAdditionalDates(
      modalAdditionalDates?.map((data, indexValue) => {
        // if (indexValue == index) {
        if (indexValue == modalAdditionalDates?.length - 1) {
          return {
            ...data,
            start: interval?.start,
            end: interval?.end,
            slotNo: interval?.slotNo,
          };
        } else {
          return data;
        }
      })
    );
  };

  const updateSelectedAdditionalDate = () => {
    if (
      modalAdditionalDates?.length > 0 &&
      !modalAdditionalDates?.every((item) => item?.slotNo != "") &&
      details?.slotType == "Time"
    ) {
      notification.error({
        message: "Please choose additional date and interval",
        description: "",
        duration: 3, // Auto close after 3 seconds
      });
      return;
    }
    setAdditionalDates(modalAdditionalDates);
    onCloseModal();
  };

  const onCloseModal = () => {
    setDateModal(false);
    setModalSelectedInterval(null);
    setModalAdditionalDates([]);
    setSelectedAdditionalDateIntervals([]);
    dateForm.resetFields();
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (dateModal) {
      setTimeout(() => {
        const modalBody = document.querySelector(
          ".additional-date-modal .ant-modal-body"
        );
        if (modalBody) {
          modalBody.scrollTop = 0; // Reset scroll position
        }
      }, 0);
    }
  }, [dateModal]);

  return (
    <div className={classes.additionalDateContainer}>
      <div className={classes.additionalDateGrid}>
        {additionalDates?.map((item, index) => (
          <div className={classes.dateCard}>
            <h1 className={classes.eventDate}>{formatDate(item.eventDate)}</h1>
            <RemoveCircleIcon
              className={classes.cardRemoveIcon}
              onClick={() => removeDate(index)}
            />
            {item.start && item.start !== "" && (
              <div className={classes.intervalSection}>
                <h4 className={classes.intervalHeading}>Interval:</h4>
                <div className={classes.intervalTimeSection}>
                  <h4 className={classes.intervalTime}>
                    {moment(item?.start, "HH:mm")?.format("h:mm A")} -{" "}
                    {moment(item?.end, "HH:mm")?.format("h:mm A")}
                  </h4>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <Button className={classes.addButton} onClick={() => setDateModal(true)}>
        <img
          src="/img/icons/plus-sign.svg"
          alt=""
          className={classes.plusImage}
        />{" "}
        Additional Date
      </Button>

      <Modal
        title={`Additional Date`}
        open={dateModal}
        onCancel={onCloseModal}
        className="additional-date-modal"
        // centered
        footer={null}
        closeIcon={<CloseIcon />}
      >
        <Calendar
          onChange={onChange}
          //   value={editMode == "EDIT" ? value : undefined}
          tileContent={tileContent}
          tileDisabled={tileDisabled}
        />

        {details?.slotType === "Time" && (
          <div
            className={classes.intervalTileWrapper}
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginBottom: "40px",
            }}
          >
            {selectedAdditionalDateIntervals?.map((item, index) => (
              <div
                onClick={() => {
                  selectedIntervalChange(index, item);
                  setModalSelectedInterval(item);
                }}
                className={
                  modalSelectedInterval === item
                    ? `${classes.intervalTile} ${classes.themeWhite}`
                    : `${classes.intervalTile} ${classes.themePink}`
                }
              >
                <div style={{ display: "flex", gap: "8px" }}>
                  <span>{moment(item?.start, "HH:mm")?.format("h:mm A")}</span>-
                  <span>{moment(item?.end, "HH:mm")?.format("h:mm A")}</span>
                </div>
                <span
                  style={{
                    color: modalSelectedInterval === item ? "white" : "#FF6666",
                  }}
                >
                  ₹{fixDecimal(Number(item.price))}
                  {details?.listingType == "Variable" ? " / Person" : ""}
                </span>
              </div>
            ))}
          </div>
        )}

        <Form
          name="custom_form"
          layout="vertical"
          form={dateForm}
          className={classes.profileForm}
        >
          <Form.Item
            name="remarks"
            rules={[{ required: false, message: "Please input your Message!" }]}
          >
            <TextArea
              placeholder="Input your remarks..."
              rows={5}
              onChange={(e) => remarkChange(e)}
            />
          </Form.Item>
        </Form>

        <Button
          option="colored"
          className={classes.addButtonModal}
          onClick={() => updateSelectedAdditionalDate()}
        >
          + Add
        </Button>
      </Modal>
      {/* <p>Selected Date: {value.toLocaleDateString()}</p>
            <p>Day of the Month: {days[value.getDay()]}</p> */}
    </div>
  );
};

export default AdditionalDateCalendar;

import React, { useEffect, useState } from "react";
import styles from "./RatingListing.module.css";
import StarRatings from "react-star-ratings";
import { Progress } from "antd";

export const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getUTCDate(); // Get day (UTC since input is Z)
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const year = date.getUTCFullYear(); // Get year (UTC)
    return `${day} ${month} ${year}`;
  };

const RatingListing = ({ reviews, details }) => {
  const [animatedValues, setAnimatedValues] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  });
  const ratings = { ...details?.ratings[0] };
  delete ratings["_id"];
  const totalRatings = Object.values(ratings).reduce(
    (sum, val) => sum + val,
    0
  );
  const [sliceAmount, setSliceAmount] = useState(4);

  useEffect(() => {
    // Start animation
    let interval = setInterval(() => {
      setAnimatedValues((prev) => {
        let newValues = { ...prev };
        let isCompleted = true;

        Object.keys(ratings).forEach((key) => {
          const targetValue = Math.round((ratings[key] / totalRatings) * 100);
          if (newValues[key] < targetValue) {
            newValues[key] = Math.min(newValues[key] + 5, targetValue);
            isCompleted = false;
          }
        });

        if (isCompleted) clearInterval(interval);
        return newValues;
      });
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.outer}>
      <div className={styles.left}>
      <div
          className={styles.rateModal}
          // onMouseEnter={() => setIsModalVisible(true)}
          // onMouseLeave={() => setIsModalVisible(false)}
        >
          <h5 className={styles.customerReviews}>Customer Reviews</h5>
          <div className={styles.rateTitle}>
            <StarRatings
              rating={details?.ratingAvg} // Rating value, can be decimal like 3.25
              starDimension="16px" // Size of the star
              starSpacing="3px" // Spacing between the stars
              starRatedColor="#FFD700" // Gold color for filled stars
              numberOfStars={5} // Max stars
              name="rating"
            />
            <h3>{details?.ratingAvg.toFixed(2)} out of 5</h3>
          </div>
          <div className={styles.rateCounts}>
            {details?.reviewCount ? details?.reviewCount : 0} ratings
          </div>
          {Object.entries(ratings)
            .reverse()
            .map(([key, value], index) => {
              const rating = 5 - index; // Convert "one" to 1, "two" to 2, etc.

              return (
                <div
                  className={styles.progressFlex}
                  key={rating}
                  style={{ marginBottom: 10 }}
                >
                  <div className={styles.ratingLine}>
                    <p>{rating}</p>
                    <p>Star</p>
                  </div>
                  <Progress
                    percent={animatedValues[key]}
                    showInfo
                    strokeWidth={14}
                    strokeLinecap="square"
                    strokeColor="#ff6666"
                    format={(percent) => (
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#0d0a19",
                        }}
                      >
                        {percent}%
                      </span>
                    )}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.right}>
      <div className={sliceAmount == 4 && reviews?.length > 4 ? styles.overLay : styles.overLayHide}>
          <div
            className={styles.readMore}
            onClick={() => {
                if(sliceAmount == 4){
              setSliceAmount(reviews?.length);
                }else{
                    setSliceAmount(4)
                }
            }}
          >
            {sliceAmount == 4 ? "View All" : "Hide Reviews"}
          </div>
        </div>
        <div className={styles.reviewsContainer}>
          {reviews?.slice(0, sliceAmount)?.map((review) => (
            <div key={review.id} className={styles.reviewCard}>
              <div className={styles.reviewHeader}>
                <img
                  src={
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                  alt={`${review.name}'s profile`}
                  className={styles.profileImage}
                />
                <h3 className={styles.reviewerName}>{review.customerName}</h3>
              </div>
              <StarRatings
              rating={review?.rating} // Rating value, can be decimal like 3.25
              starDimension="16px" // Size of the star
              starSpacing="3px" // Spacing between the stars
              starRatedColor="#FFD700" // Gold color for filled stars
              numberOfStars={5} // Max stars
              name="rating"
            />
              <p className={styles.reviewDate}>{formatDate(review.createdAt)}</p>
              <p className={styles.reviewText}>{review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RatingListing;

import React, { useRef, useState } from "react";
import classes from "./Gallery.module.css";
import { Image, Modal, message } from "antd";
import RightLongArrow from "components/UI/icons/RightLongArrow";
import useWindowWidth from "helpers/useWindowWidth";
import ReactPlayer from "react-player";
import PlayButton from "components/UI/icons/PlayButton";
import CloseIcon from "components/UI/icons/CloseIcon";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { VenueDetailDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";

const sampleImages = [
  "/img/login-wall.png",
  "/img/signup-wall.png",
  "/img/login-wall.png",
  "/img/signup-wall.png",
  "/img/login-wall.png",
  "/img/signup-wall.png",
  "/img/login-wall.png",
  "/img/signup-wall.png",
];
const sampleVideos = [
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=KnIF85jtM1w&ab_channel=TamarindWeddings",
    videoId: "KnIF85jtM1w",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=iy7Keg7oAYk&ab_channel=OLASAM%21",
    videoId: "iy7Keg7oAYk",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=TpVvNHGn090&ab_channel=1Plus1Studio",
    videoId: "TpVvNHGn090",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=0PhFbWAAkKM&ab_channel=EventManagementKerala%2CWeddingVenueThrissur",
    videoId: "0PhFbWAAkKM",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=KnIF85jtM1w&ab_channel=TamarindWeddings",
    videoId: "KnIF85jtM1w",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=iy7Keg7oAYk&ab_channel=OLASAM%21",
    videoId: "iy7Keg7oAYk",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=TpVvNHGn090&ab_channel=1Plus1Studio",
    videoId: "TpVvNHGn090",
  },
  {
    title: "Sample Video",
    videoUrl:
      "https://www.youtube.com/watch?v=0PhFbWAAkKM&ab_channel=EventManagementKerala%2CWeddingVenueThrissur",
    videoId: "0PhFbWAAkKM",
  },
];

const Gallery = () => {
  const { venueId } = useParams();
  console.log("params in gallery", venueId);
  const width = useWindowWidth();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [playing, setPlaying] = useState(true);
  const [galleryType, setGalleryType] = useState("photo");
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log('user in gallery', user)

  const playerRef = useRef(null);

  const {
    isLoading,
    data: venueDetail,
    refetch,
  } = useQuery(
    [VenueDetailDataKey, venueId, user],
    async () => {
      const response = await fetch(`${API_BASE_URL}listing/get/slug/public/${venueId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        message.error(data.message);
        throw new Error(data.message);
      }

      return data.data;
    }
    // {
    //   retry: 0, // Will retry failed requests 10 times before displaying an error
    //   retryDelay: 1000,
    //   initialData: [],
    // }
  );

  console.log("venue details", venueDetail);

  const handleUnmountVideo = () => {
    if (playerRef.current) {
      playerRef.current.unmount();
    }
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setModalOpen(true);
    setPlaying(true);
  };

  const handleVideoClose = () => {
    setModalOpen(false);
    setPlaying(false);
  };

  const getVideoThumbnail = async (url) => {
    const player = new ReactPlayer({ url });
    return player.getThumbnail();
  };

  const handleButtonClick1 = () => {
    setGalleryType("photo");
  };

  const handleButtonClick2 = () => {
    setGalleryType("video");
  };

  const getVideoId = (videoUrl) => {
    // Regular expression to extract the video ID from the YouTube URL
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    // Extracting video ID using regular expression match
    const match = videoUrl.match(regex);
    const videoId = match && match[1]; // Extracted video ID
    console.log("video id ", videoId);
    return videoId;
  };

  return (
    <div className={classes.galleryContainer}>
      <div className={classes.galleryWrapper}>
        <div className={classes.topSection}>
          <div className={classes.titleSection}>
            <h1 className={classes.title}>Gallery</h1>
            <h1 className={classes.description}>
              Capture the essence of your event in our gallery
            </h1>
          </div>
          <ButtonGroup
            buttonCount={2}
            buttonTexts={["Photos", "Videos"]}
            onClickFunctions={[handleButtonClick1, handleButtonClick2]}
          />
        </div>
        <div className={classes.galleryGrid}>
          {galleryType === "photo" && (
            <Image.PreviewGroup>
              {venueDetail?.images?.map((cur, i) => (
                // used same classname to correct border radius of preview mask
                <div className="venueImage">
                  <Image className="venueImage" key={i} src={cur} />
                </div>
              ))}
            </Image.PreviewGroup>
          )}
          {galleryType === "video" && (
            <>
              {venueDetail?.videos?.map((cur, i) => (
                <div
                  className={classes.venueImage}
                  onClick={() => handleVideoClick(cur)}
                >
                  <img
                    // className="lazy"
                    src={`https://img.youtube.com/vi/${getVideoId(
                      cur.link
                    )}/0.jpg`}
                    alt={cur.videoId}
                    className={classes.thumbnail}
                  />
                  {/* <img
                    // className="lazy"
                    src={`https://img.youtube.com/vi/${cur.videoId}/0.jpg`}
                    alt={cur.videoId}
                    className={classes.thumbnail}
                  /> */}
                  <PlayButton className={classes.playButton} />
                </div>
              ))}
            </>
          )}
        </div>

        {galleryType === "video" && (
          <Modal
            title={selectedVideo?.title}
            centered
            open={modalOpen}
            onCancel={handleVideoClose}
            className={classes.videoModal}
            destroyOnClose //to stop play after closing
            footer={null}
            closeIcon={<CloseIcon />}
            // width={"55vw"}
            // bodyStyle={{ height: "50vh", overflow: "auto" }}
            // height={"800px"}
            // style={{ height: "400px" }}
            // style={{ overflowY: "auto" }}
          >
            <div className={classes.modalBody}>
              {playing && (
                <ReactPlayer
                  // url={selectedVideo?.videoUrl}
                  url={selectedVideo?.link}
                  // ref={playerRef}
                  playing={playing}
                  controls
                  width="100%"
                  height="100%"
                  stopOnUnmount
                />
              )}
              {/* <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${selectedVideo?.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={selectedVideo?.title}
            /> */}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Gallery;

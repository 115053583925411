import React, { useEffect, useState } from "react";
import classes from "./VenueDetail.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import VenueCarousel from "./VenueCarousel";
import PriceDetail from "./PriceDetail";
import ItemInfo from "./ItemInfo";
import Timings from "./Timings";
import DetailGallery from "./DetailGallery";
import DefaultSlider from "../home-content/DefaultSlider/DefaultSlider";
import ReviewSection from "./ReviewSection";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SettingsDataKey, VenueDetailDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { Form, message } from "antd";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { cartActions } from "store-redux/cart-slice";
import RatingListing from "./rating-listing/RatingListing";

const VenueDetail = () => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const filters = useSelector((state) => state.filter);
  console.log("user in venuedetail", user);
  const { venueId } = useParams();
  console.log("venueId", venueId);
  const [addOnForm] = Form.useForm();

  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedSlotPrice, setSelectedSlotPrice] = useState(0);
  const [selectedInterval, setSelectedInterval] = useState("");
  console.log("selected interval", selectedInterval);
  const [dayValue, setDayValue] = useState("");
  console.log("dayValue", dayValue);
  const [intervals, setIntervals] = useState([]);
  console.log("intervals", intervals);
  const [selectedDate, setSelectedDate] = useState();
  console.log("selectedDate", selectedDate);
  const [similarInCategories, setSimilarInCategories] = useState([]);
  console.log("similarInCategories", similarInCategories);
  const [similarInLocations, setSimilarInLocations] = useState([]);
  const [similarInVendors, setSimilarInVendors] = useState([]);
  console.log("similarInLocations", similarInLocations);
  const [noOfPeople, setNoOfPeople] = useState(undefined);
  const [additionalDates, setAdditionalDates] = useState([]);
  const [ratingData, setRatingData] = useState([]);

  const {
    isLoading,
    data: venueDetail,
    refetch: refetchListing,
  } = useQuery(
    [VenueDetailDataKey, venueId, user],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}listing/get/slug/public/${venueId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        if (data?.statusCode != 500) {
          message.error(data.message);
        }
        throw new Error(data.message);
      }

      return data.data;
    }
    // {
    //   retry: 0, // Will retry failed requests 10 times before displaying an error
    //   retryDelay: 1000,
    //   initialData: [],
    // }
  );

  const fetchratings = async () => {
    setRatingData([])
    const response = await fetch(`${API_BASE_URL}ratings/get/all/ratings?listingId=${venueDetail?._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      if (data?.statusCode != 500) {
        message.error(data.message);
      }
      throw new Error(data.message);
    }
    setRatingData(data?.data);

    return data.data;
  };

  useEffect(() => {
    if(venueDetail?._id)
    fetchratings();
  }, [venueDetail]);

  console.log("rating data rrr", ratingData);

  console.log("venue detail rrr", venueDetail);
  console.log("similar cakes", similarInCategories);

  const fetchSimilarCategories = async () => {
    const response = await fetch(
      `${API_BASE_URL}listing/get/all/public?` +
        new URLSearchParams({
          latitude: filters?.latitude,
          longitude: filters?.longitude,
          categoryId: venueDetail?.category,
          range: 1000,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("similar categories -", data);
    if (data.data) {
      const similarData = data.data;
      setSimilarInCategories(
        data?.data
          ?.filter((item) => item?._id != venueDetail?._id)
          ?.map((item) => ({
            title: item.name,
            slug: item?.slug,
            city: item.location.locationName,
            state: "Kerala",
            price: item?.availableDates?.[0]?.intervals?.[0]?.price ?? "",
            strikePrice: 15000,
            ratingAvg: item?.ratingAvg,
            reviewCount: item?.reviewCount,
            img: item.images[0],
            categoryName: item.categoryName,
            _id: item._id,
          }))
      );
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  const fetchBySimilarLocation = async () => {
    const response = await fetch(
      `${API_BASE_URL}listing/get/all/public?` +
        new URLSearchParams({
          latitude: filters?.latitude,
          longitude: filters?.longitude,
          range: 1000,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("similar locations -", data);
    if (data.data) {
      const similarData = data.data;
      setSimilarInLocations(
        data?.data
          ?.filter((item) => item?._id != venueDetail?._id)
          ?.map((item) => ({
            title: item.name,
            slug: item?.slug,
            city: item.location.locationName,
            state: "Kerala",
            price: item?.availableDates[0]?.intervals[0]?.price,
            strikePrice: 15000,
            ratingAvg: item?.ratingAvg,
            reviewCount: item?.reviewCount,
            img: item.images[0],
            categoryName: item.categoryName,
            _id: item._id,
          }))
      );
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  const fetchSimilarVendors = async () => {
    const response = await fetch(
      `${API_BASE_URL}listing/get/all/public?` +
        new URLSearchParams({
          //latitude: filters?.latitude,
          //longitude: filters?.longitude,
          vendorId: venueDetail?.vendor,
          //range: 1000,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("similar vendor -", data);
    if (data.data) {
      const similarData = data.data;
      setSimilarInVendors(
        data?.data
          ?.filter((item) => item?._id != venueDetail?._id)
          ?.map((item) => ({
            title: item.name,
            slug: item?.slug,
            city: item.location.locationName,
            state: "Kerala",
            price: item?.availableDates?.[0]?.intervals?.[0]?.price ?? "",
            strikePrice: 15000,
            ratingAvg: item?.ratingAvg,
            reviewCount: item?.reviewCount,
            img: item.images[0],
            categoryName: item.categoryName,
            _id: item._id,
          }))
      );
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  useEffect(() => {
    if (venueDetail) {
      fetchSimilarCategories();
      fetchBySimilarLocation();
      fetchSimilarVendors();
    }
  }, [venueDetail]);

  const getCartData = async () => {
    const response = await fetch(`${API_BASE_URL}cart/list/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }

    dispatch(cartActions.toggleCart({ cartTotal: data.total }));
    // return data.data;
  };

  const { isLoading: addToCartLoading, mutateAsync: submitAddToCart } =
    useMutation(async () => {
      if (
        (!noOfPeople || noOfPeople == "") &&
        venueDetail?.listingType == "Variable"
      ) {
        message.error("Please Add Number of People");
        return;
      }
      let payload = {
        listingId: venueDetail?._id,
        selectedDate: selectedDate,
        addOns: selectedAddOns,
      };
      // if (noOfPeople) {
      if (noOfPeople) {
        payload.numberOfPeople = Number(noOfPeople);
      }

      if (selectedAddOns.length > 0) {
        payload.addOns = selectedAddOns;
      }

      if (selectedAddOns.length > 0) {
        payload.additionalDates = additionalDates?.map((item) => {
          if (venueDetail?.slotType == "Time") {
            return {
              eventDate: item?.eventDate,
              start: item?.start,
              end: item?.end,
              slotNo: item?.slotNo,
              remarks: item?.remarks,
            };
          } else {
            return {
              eventDate: item?.eventDate,
              slots: 1,
              remarks: item?.remarks,
            };
          }
        });
      }

      if (venueDetail?.slotType === "Time") {
        payload.start = selectedInterval.start;
        payload.end = selectedInterval.end;
        payload.slotNo = selectedInterval.slotNo;
      }

      if (venueDetail?.slotType === "Slot") {
        payload.slots = Number(1);
      }
      console.log("payload", payload);

      const response = await fetch(`${API_BASE_URL}cart/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log("response data,", data);
      if (!response.ok) {
        message.error(data.message);
        throw new Error(data.message);
      }

      // return data.data;
      if (data.success) {
        message.success("Added to Cart");
        getCartData();
        refetchListing();
      }
      // }
      // { mutationKey: "FILES_MANGER" }
    });

  const compiledDays = venueDetail?.daysWithIntervals
    ?.map((obj) => obj.day)
    .flat();
  console.log("days combined", compiledDays);

  const compiledIntervals = venueDetail?.daysWithIntervals?.flatMap((obj) =>
    obj.intervals?.map((interval) => ({
      start: interval.start,
      end: interval.end,
    }))
  );
  console.log("timings combined", compiledIntervals);

  return (
    <>
      {!venueDetail && !isLoading && (
        <h4 className="home-error">No Data Found</h4>
      )}
      {isLoading && <LoadingSpinner />}
      {venueDetail && (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.venueInfo}>
              <VenueCarousel images={venueDetail?.images} />

              {/* Venue name and details */}
              <ItemInfo details={venueDetail} />

              {windowWidth < 1024 && (
                // PriceCard for mobile screen
                <div className={classes.priceDetails}>
                  <PriceDetail
                    details={venueDetail}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                    dayValue={dayValue}
                    setDayValue={setDayValue}
                    intervals={intervals}
                    setIntervals={setIntervals}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    submitAddToCart={submitAddToCart}
                    addToCartLoading={addToCartLoading}
                    selectedSlotPrice={selectedSlotPrice}
                    setSelectedSlotPrice={setSelectedSlotPrice}
                    // details={sampleVenueDetail?.priceDetails}
                    additionalDates={additionalDates}
                    setAdditionalDates={setAdditionalDates}
                    addOnForm={addOnForm}
                  />
                </div>
              )}

              {/* Venue weekdays and timings */}
              <Timings
                days={compiledDays}
                timings={compiledIntervals}
                details={venueDetail}
                // days={sampleVenueDetail?.availableDays}
                // timings={sampleVenueDetail?.availableTimings}
              />

              {/* Gallery of images and videos */}
              <DetailGallery
                images={venueDetail?.images}
                // images={sampleVenueDetail?.sampleImages}
                videos={venueDetail?.videos}
                // videos={sampleVenueDetail?.sampleVideos}
                venueId={venueDetail?.slug}
              />

              {/* Event section */}
              {venueDetail?.eventTypes?.length > 0 && (
                <div className={classes.amenities}>
                  <h1 className={classes.title}>Events</h1>
                  <div className={classes.tagWrapper}>
                    {venueDetail?.eventTypes?.map((item) => (
                      <div className={classes.tag}>{item.eventTypeName}</div>
                    ))}
                    {/* {sampleVenueDetail?.amenities?.map((item) => (
                      <div className={classes.tag}>{item}</div>
                    ))} */}
                  </div>
                </div>
              )}

              {/* Venue description */}
              <div className={classes.description}>
                <h3 className={classes.heading}>
                  About {venueDetail?.name}
                  {/* About {sampleVenueDetail?.title} */}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: venueDetail?.description,
                  }}
                  className={`${classes.paragraph} description-content`}
                >
                  {/* {sampleVenueDetail?.description} */}
                </p>
              </div>

              {/* Amenities section */}
              {venueDetail?.amenitiesNames?.length > 0 && (
                <div className={classes.amenities}>
                  <h1 className={classes.title}>Amenities</h1>
                  <div className={classes.tagWrapper}>
                    {venueDetail?.amenitiesNames?.map((item) => (
                      <div className={classes.tag}>{item.amenityName}</div>
                    ))}
                    {/* {sampleVenueDetail?.amenities?.map((item) => (
                      <div className={classes.tag}>{item}</div>
                    ))} */}
                  </div>
                </div>
              )}
            </div>

            {windowWidth >= 1024 && (
              // PriceCard for desktop screen
              <div className={classes.priceDetails}>
                <PriceDetail
                  details={venueDetail}
                  selectedAddOns={selectedAddOns}
                  setSelectedAddOns={setSelectedAddOns}
                  selectedInterval={selectedInterval}
                  setSelectedInterval={setSelectedInterval}
                  dayValue={dayValue}
                  setDayValue={setDayValue}
                  intervals={intervals}
                  setIntervals={setIntervals}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  submitAddToCart={submitAddToCart}
                  addToCartLoading={addToCartLoading}
                  selectedSlotPrice={selectedSlotPrice}
                  setSelectedSlotPrice={setSelectedSlotPrice}
                  venueDetail={venueDetail}
                  noOfPeople={noOfPeople}
                  setNoOfPeople={setNoOfPeople}
                  additionalDates={additionalDates}
                  setAdditionalDates={setAdditionalDates}
                  addOnForm={addOnForm}
                  // details={sampleVenueDetail?.priceDetails}
                />
              </div>
            )}
          </div>
          {ratingData?.length > 0 && (
            <RatingListing reviews={ratingData} details={venueDetail} />
          )}
          {/* Review Section */}
          {/* <ReviewSection ratingsData={sampleVenueDetail?.ratingsData} /> */}

          {/* similar data sliders */}
          {similarInVendors?.length > 0 && (
            <DefaultSlider
              data={similarInVendors}
              // data={data}
              title={`Listings of ${venueDetail?.vendorName}`}
              containerClass={classes.slider}
            />
          )}
          {similarInCategories?.length > 0 && (
            <DefaultSlider
              data={similarInCategories}
              // data={data}
              title={`Similar ${venueDetail?.categoryName}`}
              containerClass={classes.slider}
            />
          )}
          {similarInLocations.length > 0 && (
            <DefaultSlider
              // data={data}
              data={similarInLocations}
              title="Other Listings in the Area"
              containerClass={classes.slider}
            />
          )}
        </div>
      )}
    </>
  );
};

export default VenueDetail;

import React, { useEffect, useState } from "react";
import classes from "./CalendarSelection.module.css";
import CalendarUI from "./Calendar";
import moment from "moment";
import { fixDecimal } from "helpers/fixDecimal";

const CalendarSelection = ({
  details,
  selectedInterval,
  setSelectedInterval,
  dayValue,
  setDayValue,
  intervals,
  setIntervals,
  selectedDate,
  setSelectedDate,
  mode,
  selectedSlotPrice,
  setSelectedSlotPrice,
  venueDetail,
}) => {
  // const [selectedListing, setSelectedListing] = useState();
  // const [dayValue, setDayValue] = useState("");
  // console.log("dayValue", dayValue);
  // const [intervals, setIntervals] = useState([]);
  // console.log("intervals", intervals);
  // const [selectedDate, setSelectedDate] = useState();
  // console.log("selectedDate", selectedDate);
  // const mode = details?.slotType;
  // console.log("mode", mode);

  useEffect(() => {
    if (dayValue) {
      const isExist = details?.customPriceDates.filter(
        (item) => item.date == selectedDate
      );
      console.log("is exists value", isExist);

      if (isExist.length > 0) {
        const custompriceValue = details?.customPriceDates?.find(
          (item) => item?.date == selectedDate
        )?.price;
        console.log("custom price value", custompriceValue, selectedDate);
        const newinterval = details?.availableDates?.find(
          (item) => item?.date == selectedDate
        )?.intervals;
        let intervalsValue = newinterval?.map((item) => ({
          ...item,
          price: custompriceValue,
        }));
        setIntervals(intervalsValue);
      } else {
        details?.availableDates?.forEach((item) => {
          if (item.date == selectedDate) {
            setIntervals(item.intervals);
          }
        });
      }
      if (details?.slotType === "Slot") {
        if (isExist.length > 0) {
          setSelectedSlotPrice(isExist[0]?.price);
        } else {
          details?.daysWithIntervals?.forEach((item) => {
            if (item.day.includes(dayValue)) {
              setSelectedSlotPrice(item.intervals[0]?.price);
              return item;
            }
          });
        }
      }
    }
  }, [dayValue]);
  return (
    <div className={classes.calendarComponent}>
      <CalendarUI
        setDayValue={setDayValue}
        details={details}
        setSelectedDate={setSelectedDate}
        mode={details?.slotType}
        dayValue={dayValue}
        intervals={intervals}
        setIntervals={setIntervals}
      />
      {details?.slotType === "Time" && intervals?.length > 0 && (
        <div
          className={classes.intervalTileWrapper}
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            marginBottom: "40px",
          }}
        >
          {intervals?.map((item) => (
            <div
              onClick={() => setSelectedInterval(item)}
              className={
                selectedInterval === item
                  ? `${classes.intervalTile} ${classes.themeWhite}`
                  : `${classes.intervalTile} ${classes.themePink}`
              }
            >
              <div style={{ display: "flex", gap: "8px" }}>
                <span>{moment(item?.start, "HH:mm")?.format("h:mm A")}</span>-
                <span>{moment(item?.end, "HH:mm")?.format("h:mm A")}</span>
              </div>
              <span
                style={{
                  color: selectedInterval === item ? "white" : "#FF6666",
                }}
              >
                ₹{fixDecimal(Number(item.price))}
                {venueDetail?.listingType == "Variable" ? " / Person" : ""}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CalendarSelection;

import { createSlice } from '@reduxjs/toolkit';

import {
  USER_EXPIRATION_TIME,
  USER_FILTER_VALUES,
  USER_LOCAL_STORAGE_KEY,
  expirationTime
} from '../helpers/constants';

const calculateRemainingTime = (expireTime) => {
  const currentTime = new Date().getTime();
  const adjExpireTime = new Date(expireTime).getTime();

  const remainingTime = adjExpireTime - currentTime;

  return remainingTime;
};

let autoLogoutTimer = null;

const widgetsSlice = createSlice({
  name: 'user',
  initialState: {
    widgets: []
  },
  reducers: {
    setWidgets(state, action) {
      console.log('widgets coming ',action)
      state.widgets = action.payload.widgets
    },
  }
});

export const widgetsActions = widgetsSlice.actions;
export default widgetsSlice.reducer;

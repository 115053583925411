import React, { useState } from "react";
import classes from "./Searchcards.module.css";
import searchData from "../SearchData";
import EventCard from "../EventCard";
import CustomPagination from "components/CustomPagination/CustomPagination";
import WeddingPagination from "components/WeddingPagination/WeddingPagination";
import { Spin } from "antd";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { Link } from "react-router-dom";

function SearchCards({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  isVisible,
  setIsVisible,
  loading,
  currentPage,
  setCurrentPage,
  totalCount,
  itemsCountPerPage,
  venueData,
  setVenueData,
  error,
}) {
  // const totalItemsCount = totalCount;
  console.log("current page", currentPage);

  return (
    <>
      <div className={classes.cardsOuter}>
        <div className={classes.appliedFilters}>
          <button onClick={() => setIsVisible(!isVisible)}>Filter</button>
          <div className={classes.filterFlex}>
            {appliedFilterData?.map((item) => (
              <div className={classes.filterContainer}>
                <h5>{item}</h5>
                <img
                  onClick={() => removeFilter(item)}
                  src="/img/icons/crossmark.svg"
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.cardFlex}>
          {venueData.map((card) => (
            <div className={classes.eventCardWrapper}>
              <Link to={`/venue/${card.slug}`}>
                <EventCard
                  listingId={card._id}
                  image={card.img}
                  title={card.title}
                  review={card.review}
                  city={card.city}
                  state={card.state}
                  price={card.price}
                  strikePrice={card.strikePrice}
                />
              </Link>
            </div>
          ))}
        </div>
        {loading && venueData?.length == 0 && <LoadingSpinner />}
        {error && venueData?.length == 0 && (
          <h4 className={`${classes.errorText} home-error`}>No Data Found</h4>
        )}
        {totalCount > itemsCountPerPage && venueData?.length != 0 && (
          <WeddingPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItemsCount={totalCount}
            itemsCountPerPage={itemsCountPerPage}
          />
        )}
      </div>
    </>
  );
}

export default SearchCards;

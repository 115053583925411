const ReactAppStage = process.env.REACT_APP_API_STAGE;


const dev = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1/"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1/"
        : "https://api.eventoq.in/api/v1/",
};

const prod = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1/"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1/"
        : "https://api.eventoq.in/api/v1/",
};

const test = {
  API_ENDPOINT_URL:
    ReactAppStage == "Dev"
      ? "https://weddingtestapi.riolabz.com/api/v1/"
      : ReactAppStage == "Uat"
        ? "https://eventoquatapi.riolabz.com/api/v1/"
        : "https://api.eventoq.in/api/v1/",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();

import React, { useEffect, useState } from "react";
import classes from "./Checkout.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import MapIconGray from "components/UI/icons/MapIconGray";
import RemoveIconCircle from "components/UI/icons/RemoveIconCircle";
import PriceDetail from "./PriceDetail";
import PaymentOptions from "./PaymentOptions";
import { API_BASE_URL } from "configs/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EventOptions from "./EventOptions";
import { message, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { cartActions } from "store-redux/cart-slice";
import { fixDecimal } from "helpers/fixDecimal";

const Cart = () => {
  const windowWidth = useWindowWidth();
  const [cartData, setCartData] = useState([]);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [noOfPeople, setNoOfPeople] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("Online");
  const [bookingLoading, setBookingLoading] = useState(false);
  const [eventTypeOptions, seteventTypeOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { itemId } = useParams();

  console.log("event type options", eventTypeOptions, cartData);
  console.log("item id is", itemId);

  useEffect(() => {
    getEventTypeList();
  }, [user]);

  const getEventTypeList = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        console.log("event options to state ---", data);
        seteventTypeOptions(data.data);
      }
    } catch (err) {}
  };

  const getData = async () => {
    const response = await fetch(`${API_BASE_URL}cart/list/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    if (data?.data?.length > 0) {
      const objectValue = {
        ...data?.data?.filter((item) => item?._id == itemId)[0],
      };
      if (objectValue?.numberOfPeople) {
        setNoOfPeople(objectValue?.numberOfPeople);
      } else {
        setNoOfPeople(undefined);
      }
    }
    setCartData(data?.data?.filter((item) => item?._id == itemId));
    if (data.statusCode === 404) {
      dispatch(cartActions.toggleCart({ cartTotal: 0 }));
    } else {
      dispatch(cartActions.toggleCart({ cartTotal: data.total }));
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const continuePayment = async () => {
    setBookingLoading(true);
    const response = await fetch(`${API_BASE_URL}booking/create/customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        cartItemId: itemId,
        paymentMode: selectedPayment,
        eventTypeId: selectedEvent,
        remarks: remarks,
        numberOfPeople: Number(noOfPeople),
      }),
    });

    const data = await response.json();

    if (data?.success) {
      getData();
      message.success("Booking created successfully...");
      navigate("/cart");
      setBookingLoading(false);
    }
  };

  const formatDateIN = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN", options);
  };

  return (
    <div className={classes.container}>
      {/* Title Section */}
      <div className={classes.titleSection}>
        <h1 className={classes.title}>Checkout</h1>
      </div>

      {/* Data section */}
      <div className={classes.cartWrapper}>
        {/* Cart Items */}
        <div className={classes.cartItemsList}>
          {cartData?.map((item) => (
            <div className={classes.cartItem}>
              <div className={classes.details}>
                <h1 className={classes.itemName}>{item.listingName}</h1>
                <div className={classes.cityWrap}>
                  <MapIconGray className={classes.cityIcon} />
                  <h2 className={classes.city}>
                    {item?.listingLocation?.locationName}
                  </h2>
                </div>
                <br className={classes.breakLine} />

                {/* section 2 */}
                <div className={classes.dateAndTime}>
                  <h1 className={classes.eventDate}>
                    Event Date : {formatDate(item.selectedDate)}
                  </h1>
                  {item?.start && item?.end && (
                    <h1 className={classes.eventDate}>
                      Timing : {moment(item?.start, "HH:mm")?.format("h:mm A")}{" "}
                      - {moment(item?.end, "HH:mm")?.format("h:mm A")}
                    </h1>
                  )}
                </div>
                {item?.additionalDates?.length > 0 && (
                  <div className={classes.addonsData}>
                    <h3 className={classes.addOnTitle}>Additional Dates:</h3>
                    {item?.additionalDates?.map((date, i) => (
                      <div className={classes.addOnItem}>
                        <Tooltip
                          placement="right"
                          trigger={["hover"]}
                          title={date.remarks}
                        >
                          <h1 className={classes.addOnItemName}>
                            {i + 1}) {formatDateIN(date.eventDate)}
                          </h1>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                )}
                {item?.addOns?.length > 0 && (
                  <div className={classes.addonsData}>
                    <h3 className={classes.addOnTitle}>Addons:</h3>
                    {item?.addOns?.map((addOnItem) => (
                      <div className={classes.addOnItem}>
                        <h1 className={classes.addOnItemName}>
                          {addOnItem.name}:{" "}
                          <span className={classes.addOnItemprice}>
                            ₹&nbsp;
                            {fixDecimal(addOnItem.total)?.toLocaleString(
                              "en-IN"
                            )}
                          </span>
                        </h1>
                        {/* <div className={classes.removeWrap}>
                          <RemoveIconCircle />
                          {(windowWidth < 747 || windowWidth >= 1200) && (
                            <h1 className={classes.removeWord}>REMOVE</h1>
                          )}
                        </div> */}
                      </div>
                    ))}
                  </div>
                )}
                <br className={classes.breakLine} />

                {/* Bottom section */}
                <div className={classes.bottomSection}>
                  {/* <h1 className={classes.totalAmount}>
                    Total Amount :&nbsp;&nbsp;
                    <span className={classes.amountTag}>
                      ₹ {fixDecimal(item?.itemTotal)?.toLocaleString("en-IN")}
                    </span>
                  </h1> */}
                  <h1 className={classes.totalAmount}>
                    Booking Amount :&nbsp;&nbsp;
                    <span className={classes.amountTag}>
                      ₹{" "}
                      {fixDecimal(item?.advanceAmount)?.toLocaleString("en-IN")}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.rightDiv}>
          <div className={classes.paymentDetails}>
            <PaymentOptions
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
            />
          </div>

          {/* Price details for desktop screen */}
          <div className={classes.eventOptions}>
            <EventOptions
              setNoOfPeople={setNoOfPeople}
              setRemarks={setRemarks}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              noOfPeople={noOfPeople}
              eventTypeOptions={eventTypeOptions}
            />
          </div>

          <div className={classes.priceDetails}>
            <PriceDetail
              cartData={cartData}
              continuePayment={continuePayment}
              bookingLoading={bookingLoading}
            />
          </div>
        </div>
        {/* Price details for mobile screen */}
        {/* {windowWidth < 1024 && (
        <div className={classes.priceDetails}>
          <PriceDetail />
        </div>
      )} */}
      </div>
    </div>
  );
};

export default Cart;

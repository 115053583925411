import React, { useEffect, useState } from "react";
import classes from "./PriceDetail.module.css";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import {
  DatePicker,
  Form,
  Input,
  Modal,
  message,
  Button as AntdButton,
  InputNumber,
  Select,
} from "antd";
import CustomDateIcon from "components/UI/icons/CustomDateIcon";
import Button from "components/UI/button/Button";
import CalendarSelection from "./calendar-selection/CalendarSelection";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import CloseIcon from "components/UI/icons/CloseIcon";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import { API_BASE_URL } from "configs/AppConfig";
import AdditionalDateCalendar from "./calendar-selection/AdditionalDateCalendar";
import { fixDecimal } from "helpers/fixDecimal";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const { Option } = Select;

const PriceDetail = ({
  details,
  selectedAddOns,
  setSelectedAddOns,
  selectedInterval,
  setSelectedInterval,
  dayValue,
  setDayValue,
  intervals,
  setIntervals,
  selectedDate,
  setSelectedDate,
  venueDetail,
  // mode,
  // setMode,
  submitAddToCart,
  addToCartLoading,
  selectedSlotPrice,
  setSelectedSlotPrice,
  noOfPeople,
  setNoOfPeople,
  additionalDates,
  setAdditionalDates,
  addOnForm,
}) => {
  console.log("selected addons", venueDetail);
  const mode = details?.slotType;
  console.log("mode", mode);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [enquirySubmitLoading, setEnquirySubmitLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [error, setError] = useState(null);
  const [warningMessage, setWarningMessage] = useState();
  // const [email, setEmail] = useState();
  // const [phoneNumber, setPhoneNumber] = useState();

  const fetchVenueTypes = async () => {
    const response = await fetch(
      `${API_BASE_URL}eventType/get/all?` +
        // new URLSearchParams({
        //   latitude: filters?.latitude,
        //   longitude: filters?.longitude,
        // }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.sessionToken}`,
          },
        }
    );
    const data = await response.json();
    console.log("event types in venue page -", data);
    if (data.data) {
      setEventTypes(data.data);
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  useEffect(() => {
    fetchVenueTypes();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldsValue({
        emailId: user?.user?.email ?? "",
        phoneNo: user?.user?.phoneNo ?? "",
      });
    }
  }, [enquiryModalOpen]);

  const changeAddOnsArray = (e, addOn) => {
    console.log("addOn onclick", addOn);
    const findIndex = selectedAddOns?.findIndex((item) => item.id == addOn._id);
    console.log("findIndex value", findIndex);
    if (findIndex != -1) {
      setSelectedAddOns(selectedAddOns.filter((data) => data.id != addOn._id));
    } else {
      setSelectedAddOns([...selectedAddOns, { id: addOn?._id }]);
    }
    addOnForm.resetFields([
      ["addOnQuantities", `quantity_${e.target.value._id}`],
    ]);

    // if (!selectedAddOns?.includes(addOn)) {
    //   setSelectedAddOns([...selectedAddOns, addOn]);
    // } else {
    //   const removedArray = selectedAddOns?.filter(
    //     (item) => item.name !== addOn.name
    //   );
    //   setSelectedAddOns(removedArray);
    // }
  };

  const updateQuantityForAddon = (value, addOn) => {
    console.log("666 value,item", value, addOn);
    // if (value < addOn.minQuantity || value > addOn.maxQuantity) {
    //   return;
    // }
    const filteredOtherData = selectedAddOns?.filter(
      (obj) => obj.id !== addOn._id
    );
    console.log("666 filteredOtherData", filteredOtherData);
    const selectedAddon = selectedAddOns?.find((obj) => obj.id === addOn._id);
    console.log("666 selectedAddon", selectedAddon);
    if (filteredOtherData?.length > 0) {
      setSelectedAddOns([
        ...filteredOtherData,
        { ...selectedAddon, quantity: value },
      ]);
      if (!value)
        addOnForm.setFieldsValue({
          addOnQuantities: {
            [`quantity_${addOn._id}`]: undefined,
          },
        });
    } else {
      setSelectedAddOns([{ ...selectedAddon, quantity: value }]);
    }
  };

  // const removeAddOnsFromArray = (addOn) => {
  //   const removedArray = selectedAddOns?.filter(
  //     (item) => item.name !== addOn.name
  //   );
  //   setSelectedAddOns(removedArray);
  // };

  const addToCart = () => {
    // try {
    //   addOnForm.validateFields();
    // } catch (err) {
    //   message.error("Please check all required fields.");
    //   return;
    // }

    if (isLoggedIn) {
      if (!selectedDate) {
        message.error("Please select a Date");
        return;
      }
      if (!selectedInterval && mode === "Time") {
        message.error("Please select a Time Slot");
        return;
      }
      addOnForm
        .validateFields()
        .then(() => {
          submitAddToCart();
        })
        .catch((info) => {
          message.error("Please check all required fields.");
          return;
        });
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const checkOpenModal = () => {
    if (!selectedDate) {
      message.error("Please select a Date");
      return;
    }
    if (!selectedInterval && mode === "Time") {
      message.error("Please select a Time Slot");
      return;
    }
    setEnquiryModalOpen(true);
  };

  const onFinishEnquiry = async () => {
    setEnquirySubmitLoading(true);
    form.validateFields().then(async (values) => {
      console.log("values, ", values);
      if (values.numberOfPeople) {
        values.numberOfPeople = Number(values.numberOfPeople);
      }
      values.listingId = details?._id;
      values.date = selectedDate;
      if (selectedInterval?.start && selectedInterval?.end) {
        values.slot = `${moment(selectedInterval?.start, "HH:mm")?.format(
          "h:mm A"
        )}-${moment(selectedInterval?.end, "HH:mm")?.format("h:mm A")}`;
      }
      // values.slot = `${selectedInterval.start}-${selectedInterval.end}`;
      console.log("final values", values);

      const response = await fetch(`${API_BASE_URL}enquiry/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();

      if (data.success) {
        message.success("Enquiry submitted.");
        setEnquiryModalOpen(false);
        setEnquirySubmitLoading(false);
        form.resetFields();
      }

      if (!response.ok) {
        message.error(data.message);
        setEnquirySubmitLoading(false);
        throw new Error(data.message);
      }
    });
  };

  const validateInput = (inputValue) => {
    if (!inputValue) {
      setError("Please input a number!");
      return false;
    }

    if (
      inputValue < venueDetail?.minNumberOfPeople &&
      venueDetail?.listingType !== "Fixed"
    ) {
      setError(
        `The number should not be less than ${venueDetail?.minNumberOfPeople} for this Listing`
      );
      return false;
    }

    setError("");
    return true;
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue == "" || inputValue == undefined || inputValue == null) {
      setNoOfPeople(undefined);
    } else {
      setNoOfPeople(inputValue);
    }

    validateInput(inputValue);
    if (inputValue > venueDetail?.maxNumberOfPeople) {
      message.warning(
        "WARNING: The number you entered exceeds the maximum number of people of the selected Listing"
      );
      setWarningMessage(
        `Maximum number of people available for '${venueDetail.name}' is ${venueDetail?.maxNumberOfPeople}!`
      );
    } else {
      setWarningMessage(null);
      return;
    }
  };

  //additional date functions

  const removeDate = (index) => {
    setAdditionalDates(
      additionalDates?.filter((item, indexvalue) => indexvalue != index)
    );
  };

  console.log("555 selectedAddons", selectedAddOns);
  return (
    <div className={classes.priceContainer}>
      {/* section 1 */}
      <div className={classes.sectionWrapper}>
        <div className={classes.heading}>Price Details</div>
        <div className={classes.listRow}>
          <h1 className={classes.rowTitle}>{details?.categoryName}</h1>
          <div className={classes.rowValueWrapper}>
            <h2 className={classes.rowValue}>
              ₹{" "}
              {fixDecimal(
                details?.availableDates[0]?.intervals[0]?.price
              )?.toLocaleString("en-IN")}
            </h2>
            {/* <h3 className={classes.rowStrikePrice}>₹ 90,000</h3> */}
          </div>
        </div>
      </div>
      {details?.addOns?.length > 0 && <br className={classes.breakLine} />}
      {/* section 2 */}
      <div className={classes.sectionWrapper}>
        {details?.addOns?.length > 0 && (
          <div className={classes.heading}>
            Addons <span className={classes.subHeading}>(Optional)</span>
          </div>
        )}
        <Form
          name="custom_form"
          layout="vertical"
          form={addOnForm}
          className={`${classes.profileForm} ${classes.addOnForm}`}
        >
          {details?.addOns?.map((addOn, index) => (
            <div className={classes.addOnContainer}>
              <div
                className={`${classes.listRow} ${
                  index === 0 && classes.firstAddonInput
                }`}
                key={index}
              >
                <h1 className={classes.rowTitle}>
                  <CheckboxInput
                    id={addOn.name}
                    name={addOn.name}
                    //   ref={acceptTermsInputRef}
                    // isCircle
                    label={
                      <div>
                        {addOn.name}{" "}
                        <span className={classes.pricingType}>
                          {`(${
                            addOn.pricingType === "VARIABLE"
                              ? `Variable - ₹ ${fixDecimal(
                                  addOn.charge
                                )?.toLocaleString("en-IN")}`
                              : `Fixed - ₹ ${fixDecimal(
                                  addOn.charge
                                )?.toLocaleString("en-IN")}`
                          })`}
                        </span>
                      </div>
                    }
                    // checked={selectedAddOns?.includes(addOn) ? true : false}
                    checked={
                      selectedAddOns?.findIndex(
                        (data) => data.id == addOn._id
                      ) != -1
                    }
                    onClick={(e) => changeAddOnsArray(e, addOn)}
                    labelClass={classes.rowTitle}
                    checkboxClass={classes.checkboxClass}
                  />
                </h1>

                <div className={classes.rowValueWrapper}>
                  <h2 className={classes.rowValue}>
                    ₹{" "}
                    {fixDecimal(
                      addOn.charge *
                        (Number(
                          selectedAddOns?.find((item) => item.id == addOn._id)
                            ?.quantity
                        ) ||
                          (selectedAddOns?.findIndex(
                            (data) => data.id == addOn._id
                          ) != -1 && addOn.pricingType !== "VARIABLE"
                            ? 1
                            : 0))
                    )?.toLocaleString("en-IN")}
                  </h2>
                </div>
              </div>
              {selectedAddOns?.findIndex((data) => data.id == addOn._id) !=
                -1 &&
                addOn.pricingType === "VARIABLE" && (
                  <div className={classes.addOnQtyDiv}>
                    <h4>{`Quantity (${addOn.minQuantity} - ${addOn.maxQuantity})`}</h4>
                    <Form.Item
                      // label={`Quantity (${addOn.minQuantity} - ${addOn.maxQuantity})`}
                      // name="quantity"
                      required
                      name={["addOnQuantities", `quantity_${addOn._id}`]} // Unique name per addon
                      key={addOn._id}
                      // style={{ marginLeft: "40px" }}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              value === undefined ||
                              value === null ||
                              value === ""
                            ) {
                              return Promise.reject("Quantity is required!");
                            }
                            if (value < addOn.minQuantity) {
                              return Promise.reject(
                                `Quantity cannot be less than ${addOn.minQuantity}`
                              );
                            }
                            if (value > addOn.maxQuantity) {
                              return Promise.reject(
                                `Quantity cannot exceed ${addOn.maxQuantity}`
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        onChange={(e) => updateQuantityForAddon(e, addOn)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                )}
            </div>
          ))}
        </Form>
        {/* <div className={classes.listRow}>
          <h1 className={classes.rowTitle}>
            <CheckboxInput
              id="Round Tables"
              name="Round Tables"
              //   ref={acceptTermsInputRef}
              // isCircle
              label="Round Tables"
              labelClass={classes.rowTitle}
              checkboxClass={classes.checkboxClass}
            />
          </h1>
          <div className={classes.rowValueWrapper}>
            <h2 className={classes.rowValue}>₹ 10,20,000</h2>
          </div>
        </div> */}
      </div>
      <br className={classes.breakLine} />
      {/* section 3 */}
      <div className={classes.inputSection}>
        <label className={classes.inputLabel}>Event Date</label>
        {/* <DatePicker
          placeholder="Choose Your Event Date"
          format={dateFormatList}
          className="dateInput"
          suffixIcon={<CustomDateIcon />}
        /> */}
        <CalendarSelection
          details={details}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
          dayValue={dayValue}
          setDayValue={setDayValue}
          intervals={intervals}
          setIntervals={setIntervals}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          mode={mode}
          selectedSlotPrice={selectedSlotPrice}
          setSelectedSlotPrice={setSelectedSlotPrice}
          venueDetail={venueDetail}
        />
        {venueDetail?.listingType == "Variable" && (
          <Input
            type="number"
            onChange={handleChange}
            className={classes.peopleInput}
            placeholder="Number of People"
          />
        )}
        {error && (
          <div
            style={{ color: "red", marginBottom: "20px", marginTop: "-15px" }}
          >
            {error}
          </div>
        )}
        {warningMessage && (
          <h5
            className={classes.warnColor}
            style={{ marginBottom: "20px", marginTop: "-15px" }}
          >
            {warningMessage}
          </h5>
        )}

        {details && (
          <div className={classes.additionalDateSection}>
            <label className={classes.inputLabel}>Additional Dates</label>
            <AdditionalDateCalendar
              details={details}
              additionalDates={additionalDates}
              setAdditionalDates={setAdditionalDates}
              selectedDate={selectedDate}
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              // addAdditionalDate={addAdditionalDate}
              removeDate={removeDate}
              // remarkChange={remarkChange}
              // selectedIntervalChange={selectedIntervalChange}
            />
          </div>
        )}

        <div className={classes.listRow}>
          <h1 className={classes.rowTitle} style={{ fontWeight: "600" }}>
            Total
          </h1>
          <div className={classes.rowValueWrapper}>
            <h2 className={classes.rowValue}>
              ₹
              {venueDetail?.listingType == "Fixed"
                ? fixDecimal(
                    selectedAddOns?.reduce(
                      // (total, item) => total + Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.pricingType === "VARIABLE" ? Number(item.quantity) * Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.charge) : Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.charge)) || 0,
                      // 0
                      (total, item) => {
                        const addOnData = details?.addOns?.find(
                          (addon) => addon._id === item.id
                        );
                        return (
                          total +
                            Number(
                              addOnData?.pricingType === "VARIABLE"
                                ? (Number(item.quantity) || 0) *
                                    Number(addOnData?.charge)
                                : Number(addOnData?.charge)
                            ) || 0
                        );
                      },
                      0
                      // ) + (selectedInterval ? selectedInterval.price : 0)
                    ) +
                      // selectedAddOns?.reduce(
                      //   (total, item) => total + Number(item.charge),
                      //   0
                      // ) +
                      (selectedInterval ? selectedInterval.price : 0) +
                      (details?.slotType === "Slot" ? selectedSlotPrice : 0)
                  )?.toLocaleString("en-IN")
                : fixDecimal(
                    selectedAddOns?.reduce(
                      // (total, item) => total + Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.pricingType === "VARIABLE" ? Number(item.quantity) * Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.charge) : Number(selectedListing?.addOns?.find((addon) => addon._id === item.id)?.charge)) || 0,
                      // 0
                      (total, item) => {
                        const addOnData = details?.addOns?.find(
                          (addon) => addon._id === item.id
                        );
                        return (
                          total +
                            Number(
                              addOnData?.pricingType === "VARIABLE"
                                ? (Number(item.quantity) || 0) *
                                    Number(addOnData?.charge)
                                : Number(addOnData?.charge)
                            ) || 0
                        );
                      },
                      0
                    ) + // selectedAddOns?.reduce(
                      //   (total, item) => total + Number(item.charge),
                      //   0
                      // ) +
                      Number(noOfPeople ? noOfPeople : 1) *
                        (selectedInterval ? selectedInterval.price : 0) +
                      Number(noOfPeople ? noOfPeople : 1) *
                        (details?.slotType === "Slot" ? selectedSlotPrice : 0)
                  ).toLocaleString("en-IN")}
            </h2>
            {/* <h3 className={classes.rowStrikePrice}>₹ 90,000</h3> */}
          </div>
        </div>
        <Button
          option="colored"
          type="submit"
          loading={addToCartLoading}
          disabled={addToCartLoading}
          className={classes.cartButton}
          onClick={() => addToCart()}
        >
          Add to Cart
        </Button>
        <Button
          option="uncolored"
          className={classes.cartButton}
          onClick={() => checkOpenModal()}
        >
          Enquiry?
        </Button>
      </div>
      <Modal
        title={`Enquiry - ${details?.name}`}
        open={enquiryModalOpen}
        onCancel={() => {
          setEnquiryModalOpen(false);
          form.resetFields();
        }}
        className="venue-modal"
        footer={null}
        closeIcon={<CloseIcon />}
      >
        <div className={classes.modalContainer}>
          <h4 className={classes.modalPoint}>
            Date&nbsp; :{" "}
            <span>{new Date(selectedDate)?.toLocaleDateString("en-IN")}</span>
          </h4>
          {details?.slotType === "Time" && (
            <h4 className={classes.modalPoint}>
              Slot&nbsp;&nbsp; :{" "}
              <span>
                {moment(selectedInterval?.start, "HH:mm")?.format("h:mm A")} -
                {moment(selectedInterval?.end, "HH:mm")?.format("h:mm A")}
              </span>
            </h4>
          )}
          <Form
            name="custom_form"
            layout="vertical"
            form={form}
            className={classes.profileForm}
            onFinish={onFinishEnquiry}
          >
            <h4>Email</h4>
            <Form.Item
              name="emailId"
              rules={[
                { required: true, message: "Please input your name!" },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input
                className={classes.nameField}
                type="email"
                placeholder="Enter Email"
                // value={name}
                // onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <h4>Phone Number</h4>
            <Form.Item
              name="phoneNo"
              rules={[
                { required: true, message: "Please input your Phone Number!" },
                {
                  pattern: /^(\+91)?\d{6}(\d{4})$/, // assuming 10-digit phone numbers
                  message: "Please enter a valid 10-digit phone number!",
                },
              ]}
            >
              <PhoneInput
                className="phone-ant-input"
                country="IN"
                defaultCountry="IN"
                // value={mobileNumber}
                // onChange={(e) => {
                //   console.log("phone input value", e);
                //   setMobileNumber(e);
                // }}
                // onBlur={checkPhoneNumber}
                // placeholder="Type Your Phone Number"
              />
              {/* <Input
              className={classes.nameField}
              // type="number"
              placeholder="Enter Phone Number"
              // value={name}
              // onChange={(e) => setName(e.target.value)}
            /> */}
            </Form.Item>
            <h4>Event Type</h4>
            <Form.Item
              name="eventTypeId"
              rules={[{ required: true, message: "Please select Event type" }]}
            >
              <Select
                style={{
                  width: "100%",
                  cursor: "pointer",
                  height: "50px",
                  // marginBottom: "18px",
                }}
              >
                {eventTypes?.map((type) => (
                  <Option value={type._id}>{type.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <h4>Number of People</h4>
            <Form.Item
              name="numberOfPeople"
              rules={[
                { required: false, message: "Please input number of people!" },
              ]}
            >
              <Input type="number" className={classes.nameField} />
            </Form.Item>
            <h4>Message</h4>
            <Form.Item
              name="message"
              rules={[
                { required: true, message: "Please input your Message!" },
              ]}
            >
              <TextArea placeholder="Input your message..." rows={5} />
            </Form.Item>
            <Form.Item>
              <AntdButton
                type="primary"
                htmlType="submit"
                block
                loading={enquirySubmitLoading}
                style={{ fontSize: "14px", fontWeight: "600", height: "40px" }}
              >
                Submit
              </AntdButton>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default PriceDetail;

import React, { useEffect, useState } from "react";
import classes from "./EventOptions.module.css";
import { Input, Select } from "antd";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { NoFormStyle } from "antd/es/form/context";

function EventOptions({
  setNoOfPeople,
  setRemarks,
  selectedEvent,
  setSelectedEvent,
  noOfPeople,
  eventTypeOptions,
}) {
  const [selectedPayment, setSelectedPayment] = useState("Online Payment");

  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [initialEvent, setInitialEvent] = useState();
  const { Option } = Select;
  console.log("local user is", user);

  useEffect(() => {
    if (eventTypeOptions?.length > 0) {
      const selectedEvent = localStorage.getItem(
        `${user?.user?.username}_BOOKING_EVENT`
      );

      setInitialEvent(selectedEvent);
      setSelectedEvent(selectedEvent);
    }
  }, [user, eventTypeOptions, selectedEvent]);

  return (
    <div className={classes.paymentOuter}>
      <div className={classes.inputOptions}>
        <Select
          className={classes.eventSelect}
          value={initialEvent}
          placeholder="Select Event Type"
          onChange={(value) => {
            localStorage.setItem(
              `${user?.user?.username}_BOOKING_EVENT`,
              value
            );
            setSelectedEvent(value);
          }}
          rules={[
            {
              required: true,
              message: "Please Select Event Type!",
            },
          ]}
          showSearch
          filterOption={(input, { children }) => {
            return children.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {eventTypeOptions?.map((item) => (
            <Option value={item._id}>{item.name}</Option>
          ))}
        </Select>
        <Input
          type="number"
          className={classes.peopleInput}
          placeholder="Number of People"
          onChange={(e) => setNoOfPeople(e.target.value)}
          value={noOfPeople}
        />
        <Input.TextArea
          rows={4}
          className={classes.remarkInput}
          placeholder="Remarks"
          onChange={(e) => setRemarks(e.target.value)}
        />
      </div>
    </div>
  );
}

export default EventOptions;

import React, { useRef } from "react";
import classes from "./DefaultSlider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventCard from "../EventCard/EventCard";
import { Link } from "react-router-dom";

function DefaultSlider({ data, title, containerClass }) {
  const outerContainerWidth = (88 * window.innerWidth) / 100;
  const cardWidth = 630;
  const gapBetweenCards = 15;
  const customArrow = "/img/right-arrow.svg";
  console.log("data value is", data);

  const sliderRef = useRef(null);
  const mappingData = data?.listingItems?.length ? data?.listingItems : data;

  const numCardsToShow =
    (outerContainerWidth + gapBetweenCards) / (cardWidth + gapBetweenCards);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numCardsToShow,
    // slidesToShow: 2.6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    margin: 15,
    //     centerMode: true, // Add centerMode
    // centerPadding: '-15px', // Adjust as needed
    centerMode: false,
    variableWidth: true,
    // nextArrow: <CustomNextArrow />,
  };

  const handleNextArrowClick = (originalOnClick) => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePreviousArrowClick = (originalOnClick) => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      console.log("value is", sliderRef.current);
    }
  };

  console.log("data value is", data);

  return (
    <div className="default-slide">
      <div className={`${classes.venuesOuter} ${containerClass} widget-gap`}>
        <h2
          className={classes.eventTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
        <img
          className={classes.rightArrow}
          onClick={handleNextArrowClick}
          src="/img/right-arrow.svg"
        />
        <img
          className={classes.leftArrow}
          onClick={handlePreviousArrowClick}
          src="/img/left-arrow.svg"
        />
        <Slider {...settings} ref={sliderRef}>
          {mappingData?.map((card, index) => (
            <div key={index}>
              <Link to={`/venue/${card.slug}`}>
                <EventCard
                  listingId={card._id}
                  image={card?.images?.[0] || card?.img}
                  title={card.name || card?.title}
                  slug={card?.slug}
                  review={card?.ratingAvg}
                  reviewCount={card?.reviewCount}
                  city={card.location?.locationName || card?.city}
                  state={card.state}
                  price={
                    card?.availableDates?.[0]?.intervals?.[0]?.price
                      ? card?.availableDates?.[0]?.intervals?.[0]?.price
                      : card?.price
                        ? card?.price
                        : ""
                  }
                  strikePrice={card.strikePrice}
                />
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default DefaultSlider;

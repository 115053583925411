import React, { useState } from "react";
import { Modal, Rate, Input, Button, message, notification } from "antd";
import "./CreateReview.css";
import classes from "./CreateReview.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";

function CreateReviewModal({
  visible,
  setModalVisible,
  onSubmit,
  bookingId,
  itemId,
}) {
  const [rating, setRating] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);

  const width = useWindowWidth();

  const handleRatingChange = (value) => {
    setRating(value);
    console.log("rating value", value);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const onCancel = () => {
    setModalVisible(false);
    setRating(0);
    setRemarks("");
  };

  const addReview = async () => {
    setLoading(true);

    const requestBody = {
      bookingId: bookingId,
      itemId: itemId,
      rating: rating,
      review: remarks !== "" ? remarks : undefined,
    };

    const response = await fetch(`${API_BASE_URL}ratings/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify(requestBody), // Convert request body to JSON string
    });

    const data = await response.json();

    if (data?.success) {
      message.success("Review Added Successfully");
      setModalVisible(false);
      setLoading(false);
    } else {
      setLoading(false);
    }

    if (!response.ok) {
      notification.error({
        message: "Error",
        description: data.message,
      });
      throw new Error(data.message);
    }

    return data;
  };

  return (
    <Modal
      className="review-modal"
      visible={visible}
      //   title="Create a Review"
      onCancel={onCancel}
      footer={null}
      width={width > 500 ? 456 : "90vw"}
      closeIcon={
        <img
          src="/img/icons/modal-close-icon.svg"
          style={{ width: "34px", height: "34px" }}
        />
      }

      //   footer={[
      //     <Button key="cancel" onClick={onCancel}>
      //       Cancel
      //     </Button>,
      //     <Button key="submit" type="primary" onClick={handleSubmit}>
      //       Submit
      //     </Button>,
      //   ]}
    >
      <div className={classes.modalWrapper}>
        <h1>Write Your Review</h1>
        <p className={classes.shareRemarks}>
          Share Your Experience: Your feedback helps us create memorable moments
          for others.
        </p>
        <Rate
          // allowHalf
          style={{ fontSize: width > 450 ? 65 : width > 370 ? 50 : 30 }}
          value={rating}
          onChange={handleRatingChange}
          allowClear={false}
        />

        <p className={classes.description}>Description</p>
        <Input.TextArea
          className={classes.remarkvalue}
          value={remarks}
          onChange={handleRemarksChange}
          rows={4}
        />

        <Button
          loading={loading}
          onClick={addReview}
          className={classes.submitButton}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default CreateReviewModal;

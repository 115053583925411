import React from 'react'

const CloseRedIcon = ({ className, onClick }) => {
    return (
        <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="#ff6666" />
            <path d="M13.1927 11.9995L16.5326 8.65967C16.6911 8.50144 16.7802 8.28672 16.7804 8.06276C16.7806 7.83879 16.6918 7.62392 16.5336 7.46541C16.3754 7.3069 16.1607 7.21774 15.9367 7.21754C15.7127 7.21735 15.4979 7.30613 15.3394 7.46435L11.9995 10.8042L8.65967 7.46435C8.50116 7.30585 8.28618 7.2168 8.06201 7.2168C7.83785 7.2168 7.62286 7.30585 7.46435 7.46435C7.30585 7.62286 7.2168 7.83785 7.2168 8.06201C7.2168 8.28618 7.30585 8.50116 7.46435 8.65967L10.8042 11.9995L7.46435 15.3394C7.30585 15.4979 7.2168 15.7128 7.2168 15.937C7.2168 16.1612 7.30585 16.3762 7.46435 16.5347C7.62286 16.6932 7.83785 16.7822 8.06201 16.7822C8.28618 16.7822 8.50116 16.6932 8.65967 16.5347L11.9995 13.1948L15.3394 16.5347C15.4979 16.6932 15.7128 16.7822 15.937 16.7822C16.1612 16.7822 16.3762 16.6932 16.5347 16.5347C16.6932 16.3762 16.7822 16.1612 16.7822 15.937C16.7822 15.7128 16.6932 15.4979 16.5347 15.3394L13.1927 11.9995Z" fill="white" />
        </svg>
    )
}

export default CloseRedIcon
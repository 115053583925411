import React, { useState } from "react";
import classes from "./BookingCards.module.css";
import CreateReviewModal from "../CreateReview/CreateReview";
import useWindowWidth from "helpers/useWindowWidth";
import CancelOrder from "../CancelOrder/CancelOrder";
import ChangeBookingDate from "../ChangeBookingDate/ChangeBookingDate";
import { API_BASE_URL } from "configs/AppConfig";
import RemoveIconCircle from "components/UI/icons/RemoveIconCircle";
import { Tooltip } from "antd";
import { fixDecimal } from "helpers/fixDecimal";

function BookingCards({
  data,
  timeStatus,
  fetchBookings,
  fetchVenue,
  setSelectedBooking,
  venueDetail,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [bookingDateModal, setBookingdateModal] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [venueData, setVenueData] = useState({});
  const width = useWindowWidth();
  const [intervals, setIntervals] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [remarks, setRemarks] = useState("");

  console.log("booking data in detail page", data);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setCancelVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setCancelVisible(false);
  };

  const handleBookingCloseModal = () => {
    setBookingdateModal(false);
    setIntervals([]);
    setSelectedDate();
    setRemarks();
  };

  console.log("close values", intervals, selectedDate, remarks);

  const changeBookingDate = async () => {
    setBookingdateModal(true);
    setSelectedBooking(data);
    fetchVenue(data?.listingId);
  };

  const handleSubmitReview = (reviewData) => {
    // Handle submitting review data
    console.log("Review Data:", reviewData);
    // setModalVisible(false);
  };

  const getPaymentStatus = (status) => {
    console.log("555 payment status", status);
    if (status == "Completed") {
      return <span className={classes.paymentGreen}>Completed</span>;
    }
    if (status == "Pending") {
      return <span className={classes.paymentRed}>Pending</span>;
    }
  };

  return (
    <>
      <div className={classes.outerWrapper}>
        <div className={classes.cardWrapper}>
          <div className={classes.imageContainer}>
            <img src={data.img} />
          </div>
          <div className={classes.detailsContainer}>
            <div className={classes.bookingNumber}>
              <p>#Booking Number : {data?.bookingNumber}</p>
            </div>
            <div className={classes.timeSection}>
              <h2>
                Event Date :{" "}
                <span className={classes.titleDate}>{data.date}</span>
              </h2>

              <div className={classes.timeAdjust}>
                {data.timing != "Invalid date - Invalid date" ? (
                  <span className={classes.redDot}></span>
                ) : (
                  ""
                )}
                <h3 className={classes.timeSchedule}>
                  {data.timing != "Invalid date - Invalid date"
                    ? "Timing : "
                    : ""}
                </h3>
                &nbsp;
                <span className={classes.exactTime}>
                  {data.timing != "Invalid date - Invalid date"
                    ? data.timing
                    : ""}
                </span>
              </div>

              <div className={classes.upcomingText}>
                <p
                  style={{
                    backgroundColor:
                      timeStatus === "Pending"
                        ? "#2cc13b"
                        : timeStatus === "Cancelled"
                        ? "#E38D3E"
                        : "rgb(255, 102, 102)",
                  }}
                >
                  {timeStatus == "Pending"
                    ? "Pending"
                    : timeStatus == "Confirmed"
                    ? "Confirmed"
                    : timeStatus == "Completed"
                    ? "Completed"
                    : "Cancelled"}
                </p>
              </div>
            </div>
            <div className={classes.addressSection}>
              <div className={classes.leftDiv}>
                <div className={classes.titleFlex}>
                  <h2 className={classes.eventTitle}>{data.title} - </h2>
                  {console.log("555 price data", data)}
                  <span>₹{data.price}</span>
                </div>
                <div className={classes.locationSection}>
                  <img src="/img/location-icon.svg" />
                  <p>{data.location}</p>
                </div>
                <p className={classes.addressDetails}>{data.address}</p>
                {/* <div className={classes.capacitySection}>
                  {data.details?.map((item) => (
                    <div className={classes.capacityDiv}>{item}</div>
                  ))}
                </div> */}
                {data?.addOns?.length > 0 && (
                  <div className={classes.addonsData}>
                    <h3 className={classes.addOnTitle}>Addons:</h3>
                    {data?.addOns?.map((addOnItem) => (
                      <div className={classes.addOnItem}>
                        <h1 className={classes.addOnItemName}>
                          {addOnItem.name}:{" "}
                          <span className={classes.addOnItemprice}>
                            ₹&nbsp;
                            {fixDecimal(addOnItem.total)?.toLocaleString(
                              "en-IN"
                            )}
                          </span>
                        </h1>
                      </div>
                    ))}
                  </div>
                )}
                {data?.charges?.items?.length > 0 && (
                  <div className={classes.addonsData}>
                    <h3 className={classes.addOnTitle}>Charge / Discount:</h3>
                    {data?.charges?.items?.map((charge) => (
                      <Tooltip title={`${charge.description}`}>
                        <div className={classes.charge}>
                          <h1 className={classes.addOnItemName}>
                            {charge.type}:{" "}
                            <span className={classes.addOnItemprice}>
                              ₹&nbsp;
                              {fixDecimal(charge?.amount)?.toLocaleString(
                                "en-IN"
                              )}
                            </span>
                          </h1>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
              </div>
              <div className={classes.rightDiv}>
                <button>
                  Payment Status :&nbsp;
                  <span>{getPaymentStatus(data.paymentStatus)}</span>
                </button>

                {(timeStatus === "Confirmed" || timeStatus === "Completed") && (
                  <button onClick={handleOpenModal}>Write a Review</button>
                )}
                {timeStatus == "Pending" && (
                  <button onClick={handleCancel}>Cancel Order</button>
                )}
                {timeStatus != "Cancelled" && (
                  <button onClick={changeBookingDate}>
                    Change Booking Date
                  </button>
                )}
              </div>
            </div>
            <div
              className={
                width > 1260 ? classes.priceSection : classes.priceHide
              }
            >
              <h3>
                Order Date :{" "}
                <span className={classes.exactTime}>{data.orderDate}</span>
              </h3>
              <h3>
                Name : <span className={classes.exactTime}>{data.name}</span>
              </h3>
              <h3>
                Total :{" "}
                <span className={classes.exactPrice}>
                  ₹{data?.bookingTotal?.toLocaleString("en-IN")}
                </span>
              </h3>
            </div>
            <div
              className={
                width > 1260 ? classes.priceSection : classes.priceHide
              }
            >
              {data?.additionalDates?.length > 0 && (
                <h3>
                  Additional Dates :{" "}
                  <span className={classes.exactTime}>
                    {data.additionalDates?.join(", ")}
                  </span>
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className={classes.priceBottomSection}>
          <h3>
            Order Date :{" "}
            <span className={classes.exactTime}>{data.orderDate}</span>
          </h3>
          <h3>
            Name : <span className={classes.exactTime}>{data.name}</span>
          </h3>
          <h3>
            Total :{" "}
            <span className={classes.exactPrice}>₹{data.itemTotal}</span>
          </h3>
        </div>
      </div>
      <CreateReviewModal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        onCancel={handleCloseModal}
        onSubmit={handleSubmitReview}
        bookingId={data?.id}
        itemId={data?.itemId}
      />
      <CancelOrder
        visible={cancelVisible}
        setVisible={setCancelVisible}
        onCancel={handleCloseModal}
        bookingId={data?.id}
        itemId={data?.itemId}
        fetchBookings={fetchBookings}
      />
      <ChangeBookingDate
        visible={bookingDateModal}
        setVisible={setBookingdateModal}
        onCancel={handleBookingCloseModal}
        bookingId={data?.id}
        itemId={data?.itemId}
        fetchBookings={fetchBookings}
        venueData={venueDetail}
        bookingData={data}
        remarks={remarks}
        setRemarks={setRemarks}
        intervals={intervals}
        setIntervals={setIntervals}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </>
  );
}

export default BookingCards;

import React, { useEffect, useState } from "react";
import classes from "./BookingLists.module.css";
import BookingCards from "../BookingCards/BookingCards";
import { API_BASE_URL } from "configs/AppConfig";

function BookingLists({ bookingData, timeStatus, fetchBookings }) {
  console.log("booking data is", bookingData);
  const [selectedBooking, setSelectedBooking] = useState("");
  console.log("selected booking", selectedBooking);
  const [venueDetail, setvenueDetail] = useState({});

  const fetchVenue = async (id) => {
    const response = await fetch(`${API_BASE_URL}listing/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const datas = await response.json();
    console.log("fetched datas is", datas);
    setvenueDetail(datas.data);
  };

  console.log("venue detail out", venueDetail);

  return (
    <div className={classes.bookingWrapper}>
      {bookingData
        ?.filter((data) => data?.itemStatus == timeStatus)
        ?.map((item) => (
          <BookingCards
            data={item}
            timeStatus={timeStatus}
            fetchBookings={fetchBookings}
            fetchVenue={fetchVenue}
            setSelectedBooking={setSelectedBooking}
            venueDetail={venueDetail}
          />
        ))}
      {bookingData?.filter((data) => data?.itemStatus == timeStatus)?.length ===
        0 && <h3 className={classes.noItems}>No Bookings found.</h3>}
    </div>
  );
}

export default BookingLists;

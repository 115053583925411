import React from "react";
import classes from "./PriceDetail.module.css";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import { DatePicker } from "antd";
import CustomDateIcon from "components/UI/icons/CustomDateIcon";
import Button from "components/UI/button/Button";
import SecurityIcon from "components/UI/icons/SecurityIcon";
import useWindowWidth from "helpers/useWindowWidth";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const PriceDetail = ({
  details,
  cartData,
  continuePayment,
  bookingLoading,
}) => {
  const windowWidth = useWindowWidth();
  const totalPrice = cartData.reduce((accumulator, item) => {
    return accumulator + item.itemTotal;
  }, 0);
  const totalAdvance = cartData.reduce((accumulator, item) => {
    return accumulator + item.advanceAmount;
  }, 0);
  console.log("total price ddd", totalPrice);
  return (
    <div>
      <div className={classes.priceContainer}>
        <div className={classes.priceWrapper}>
          {/* section 1 */}
          <div className={classes.sectionWrapper}>
            <div className={classes.heading}>Price Details</div>
            {/* <div className={classes.listRow}>
              <h1 className={classes.rowTitle}>Total Price</h1>
              <div className={classes.rowValueWrapper}>
                <h2 className={classes.rowValue}>
                  ₹ {800000?.toLocaleString("en-IN")}
                </h2>
              </div>
            </div> */}
            {/* <div className={classes.listRow}>
              <h1 className={classes.rowTitle}>Discount</h1>
              <div className={classes.rowValueWrapper}>
                <h2 className={classes.rowValueGray}>
                  - ₹ {20000?.toLocaleString("en-IN")}
                </h2>
              </div>
            </div> */}
            {/* to cop for the design in 767px */}
            {/* {windowWidth >= 767 && windowWidth < 1024 && (
              <br className={classes.breakLine} />
            )} */}
          </div>

          {/* to cop for the design in 767px */}
          {/* {(windowWidth < 747 || windowWidth >= 1024) && (
            <br className={classes.breakLine} />
          )} */}
          {/* section 2 */}
          <div className={classes.inputSection}>
            <div className={classes.listRow}>
              <h1 className={classes.rowTitle} style={{ fontWeight: "400" }}>
                Total Amount
              </h1>
              <div className={classes.rowValueWrapper}>
                <h2 className={classes.rowValueGray}>
                  ₹ {totalPrice?.toLocaleString("en-IN")}
                </h2>
              </div>
            </div>
            <div className={classes.listRow}>
              <h1 className={classes.rowTitle} style={{ fontWeight: "400" }}>
                Booking Amount
              </h1>
              <div className={classes.rowValueWrapper}>
                <h2 className={classes.rowValueGray}>
                  ₹ {totalAdvance?.toLocaleString("en-IN")}
                </h2>
              </div>
            </div>
            {/* to cop for the design in 767px */}
            {(windowWidth > 747 || windowWidth <= 1024) && (
              <Button
                option="colored"
                type="submit"
                loading={bookingLoading}
                disabled={bookingLoading}
                className={classes.cartButton}
                onClick={continuePayment}
              >
                Continue Payment
              </Button>
            )}
            {windowWidth < 747 && (
              <div className={classes.payInfo}>
                <SecurityIcon className={classes.securityIcon} />
                <h1 className={classes.words}>
                  Secure Payments. Book your event safely
                </h1>
              </div>
            )}
          </div>
        </div>
        {/* to cop for the design in 767px */}
        {/* {windowWidth >= 767 && windowWidth < 1024 && (
          <div className={classes.tabView}>
            <Button
              option="colored"
              type="submit"
              // loading={isLoading}
              // disabled={isLoading}
              className={classes.cartButton}
            >
              Book Now
            </Button>
            <div className={classes.payInfo}>
              <SecurityIcon className={classes.securityIcon} />
              <h1 className={classes.words}>
                Secure Payments. Book your event safely
              </h1>
            </div>
          </div>
        )} */}
      </div>
      {/* to cop for the design of showing this inside box upto 1024px and outside that beyond 1024px */}
      {windowWidth >= 767 && (
        <div className={classes.payInfo}>
          <SecurityIcon className={classes.securityIcon} />
          <h1 className={classes.words}>
            Secure Payments. Book your event safely
          </h1>
        </div>
      )}
    </div>
  );
};

export default PriceDetail;

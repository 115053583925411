import React, { useEffect, useState } from "react";
import classes from "./WishList.module.css";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import SearchCards from "../search-content/SearchCards/SearchCards";
import WishListCards from "./WishListCards";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import WishListPackageCards from "./PackageCards/WishListPackageCards";
import Button from "components/UI/button/Button";
import { API_BASE_URL } from "configs/AppConfig";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { wishlistActions } from "store-redux/wishlist-slice";
import { modalActions } from "store-redux/modal-slice";

function WishList() {
  const navigate = useNavigate();
  const [clearLoading, setClearLoading] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    } else if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  }, [isLoggedIn]);

  const handleButtonClick1 = () => {
    // Function for the first button
    navigate("/wishlist/categories");
  };

  const handleButtonClick2 = () => {
    // Function for the second button
    console.log("Button 2 clicked");
    navigate("/wishlist/packages");
  };

  const removeAll = async () => {
    setClearLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/clear/all`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        // Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (data.success) {
      setClearLoading(false);
      dispatch(wishlistActions.emptyWishlist());
      message.error("Removed all items from Wishlist.");
    }

    if (!response.ok) {
      message.error(data.message);
      setClearLoading(false);
      throw new Error(data.message);
    }
  };

  return (
    <div className={classes.wishListContainer}>
      <div className={classes.headerSection}>
        <div className={classes.titleSection}>
          <h1>Wishlist</h1>
          <p>Your Dream Event Awaits: Wishlist Your Favorites!</p>
        </div>
        {/* <ButtonGroup
          buttonCount={2}
          buttonTexts={["Categories", "Packages"]}
          onClickFunctions={[handleButtonClick1, handleButtonClick2]}
        /> */}
        <div className={classes.buttonWrapper}>
          {/* <ButtonGroup
            buttonCount={1}
            buttonTexts={["Categories"]}
            onClickFunctions={[handleButtonClick1]}
          /> */}
          {wishlist?.length > 0 && (
            <Button
              onClick={() => removeAll()}
              loading={clearLoading}
              disabled={clearLoading}
              className={classes.removeAllButton}
            >
              Remove All
            </Button>
          )}
        </div>
      </div>
      {/* <div className={classes.wishListCards}>
        <WishListCards />
      </div> */}
      <Routes>
        <Route path="categories" element={<WishListCards />} />
        <Route path="packages" element={<WishListPackageCards />} />
        <Route path="*" element={<Navigate to="categories" replace />} />
      </Routes>
    </div>
  );
}

export default WishList;

import React, { useEffect, useState } from "react";
import classes from "./ItemInfo.module.css";
import MapIconGray from "components/UI/icons/MapIconGray";
import WishlistIcon from "components/UI/icons/WishlistIcon";
import ShareIcon from "components/UI/icons/ShareIcon";
import StarRatings from "react-star-ratings";
import { Modal, Progress, Rate, message } from "antd";
import CloseIcon from "components/UI/icons/CloseIcon";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "configs/AppConfig";
import { wishlistActions } from "store-redux/wishlist-slice";
import WishlistIconWhite from "components/UI/icons/WishlistIconWhite";
import { LoadingOutlined } from "@ant-design/icons";
import { modalActions } from "store-redux/modal-slice";

const ItemInfo = ({ details }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  console.log("share modal", showShareModal);
  const currentUrl = window.location.href;
  console.log("currentUrl", details);

  const { wishlist } = useSelector((state) => state.wishlist);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [animatedValues, setAnimatedValues] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
  });
  // const ratings = { one: 100, two: 432, three: 300, four: 250, five: 120 };
  const ratings = { ...details?.ratings[0] };
  delete ratings["_id"];
  const totalRatings = Object.values(ratings).reduce(
    (sum, val) => sum + val,
    0
  );
  // const totalRatings = Object.entries(ratings)
  // .filter(([key]) => key !== "_id") // Exclude _id if it exists
  // .reduce((sum, [, value]) => sum + value, 0) || 1; // Prevent division by zero

  useEffect(() => {
    if (isModalVisible) {
      // Start animation
      let interval = setInterval(() => {
        setAnimatedValues((prev) => {
          let newValues = { ...prev };
          let isCompleted = true;

          Object.keys(ratings).forEach((key) => {
            const targetValue = Math.round((ratings[key] / totalRatings) * 100);
            if (newValues[key] < targetValue) {
              newValues[key] = Math.min(newValues[key] + 5, targetValue);
              isCompleted = false;
            }
          });

          if (isCompleted) clearInterval(interval);
          return newValues;
        });
      }, 30);

      return () => clearInterval(interval);
    } else {
      // Reset animation when closing modal
      setAnimatedValues({ one: 0, two: 0, three: 0, four: 0, five: 0 });
    }
  }, [isModalVisible]);

  const removeItemFromWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/${id}/delete`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    console.log("wishlist removal -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.error("Item successfully removed from Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const addItemToWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        listingId: id,
      }),
    });
    const data = await response.json();
    console.log("wishlist adding -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.success("Item added to Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const wishlistHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
      return;
    }
    if (wishlist?.some((item) => item.listingId === details?._id)) {
      //to remove item from wishlist if its already in wishlist
      const wishlistItemId = wishlist.find(
        (item) => item.listingId === details?._id
      );
      console.log("wishlist item to be removed", wishlistItemId);
      removeItemFromWishlist(wishlistItemId._id);
    } else {
      //to add item to wishlist if its not in wishlist
      addItemToWishlist(details?._id);
    }
  };

  const StarRating = ({ avgRating, maxStars = 5 }) => {
    console.log("avg rating is", avgRating);
    return (
      <div
        onMouseEnter={() => setIsModalVisible(true)}
        onMouseLeave={() => setIsModalVisible(false)}
        className={classes.rateOuter}
      >
        {isModalVisible && (
          <>
            <div
              className={classes.rateModal}
              // onMouseEnter={() => setIsModalVisible(true)}
              // onMouseLeave={() => setIsModalVisible(false)}
            >
              <div className={classes.rateTitle}>
                <StarRatings
                  rating={avgRating} // Rating value, can be decimal like 3.25
                  starDimension="16px" // Size of the star
                  starSpacing="3px" // Spacing between the stars
                  starRatedColor="#FFD700" // Gold color for filled stars
                  numberOfStars={5} // Max stars
                  name="rating"
                />
                <h3>{avgRating.toFixed(2)} out of 5</h3>
              </div>
              <div className={classes.rateCounts}>
                {details?.reviewCount ? details?.reviewCount : 0} ratings
              </div>
              {Object.entries(ratings)
                .reverse()
                .map(([key, value], index) => {
                  const rating = 5 - index; // Convert "one" to 1, "two" to 2, etc.

                  return (
                    <div
                      className={classes.progressFlex}
                      key={rating}
                      style={{ marginBottom: 10 }}
                    >
                      <div className={classes.ratingLine}>
                        <p>{rating}</p>
                        <p>Star</p>
                      </div>
                      <Progress
                        percent={animatedValues[key]}
                        showInfo
                        strokeWidth={14}
                        strokeLinecap="square"
                        strokeColor="#ff6666"
                        format={(percent) => (
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#0d0a19",
                            }}
                          >
                            {percent}%
                          </span>
                        )}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {/* <Rate
          disabled
          allowHalf
          value={avgRating}
          count={maxStars}
          style={{ fontSize: `${16}px`, color: "#FFD700", cursor: "pointer" }} // Adjust star size
          className="custom-star-rating"
        /> */}
        <div style={{cursor: "pointer"}} className={classes.rateWrapper}>
        <StarRatings
          rating={avgRating} // Rating value, can be decimal like 3.25
          starDimension="18px" // Size of the star
          starSpacing="3px" // Spacing between the stars
          starRatedColor="#FFD700" // Gold color for filled stars
          numberOfStars={5} // Max stars
          name="rating"
        />
       <span className={classes.downArrow}>&gt;</span>

        </div>
      </div>
    );
  };

  return (
    <div className={classes.infoContainer}>
      <div className={classes.leftSection}>
        <h1 className={classes.name}>{details?.name}</h1>
        {details?.ratingAvg && (
        <div className={classes.reviewSection}>
          {/* <img src="/img/star-icon.svg" /> */}
          {/* <h3>{StarRating(review ? Number(review) : 0)}</h3> */}
          <StarRating avgRating={details?.ratingAvg} size={14} /> &nbsp;
          <span className={classes.reviewCount}>
            ({details?.reviewCount ? details?.reviewCount : 0})
          </span>
        </div>
        )}
        <div className={classes.cityWrapper}>
          <MapIconGray className={classes.cityIcon} />
          {/* Kochi , Kerala */}
          {details?.location?.locationName}
        </div>
        <h2 className={classes.address}>
          {details?.address}
          {/* Dream Arena , Marcus Road , Kanadikkand , Kochi , Ernakulam , PO
          685059 */}
        </h2>
      </div>
      <div className={classes.rightSection}>
        {/* <div className={classes.ratingWrapper}>
          <StarRatings
            rating={3.403}
            starDimension="18px"
            starSpacing="2.25px"
            starRatedColor="#FFE234"
          />
          <h1>4.7 Rating</h1>
        </div> */}
        <div className={classes.iconWrapper}>
          {apiLoading && (
            <LoadingOutlined
              style={{ fontSize: "28px" }}
              className={classes.loadingIcon}
            />
          )}
          {wishlist?.some((item) => item.listingId === details?._id) &&
            !apiLoading && (
              <WishlistIconWhite
                onClick={(e) => wishlistHandler(e)}
                className={classes.wishlistIcon}
              />
            )}
          {!wishlist?.some((item) => item.listingId === details?._id) &&
            !apiLoading && (
              <WishlistIcon
                onClick={(e) => wishlistHandler(e)}
                className={classes.wishlistIcon}
              />
            )}
          <ShareIcon
            className={classes.shareIcon}
            onClick={() => setShowShareModal(true)}
          />
        </div>
      </div>
      <Modal
        title="Share link via Social Media"
        // centered
        open={showShareModal}
        onCancel={() => setShowShareModal(false)}
        className={classes.videoModal}
        footer={null}
        closeIcon={<CloseIcon />}
        // width={"55vw"}
        // bodyStyle={{ height: "50vh", overflow: "auto" }}
        // height={"800px"}
        // style={{ height: "400px" }}
        // style={{ overflowY: "auto" }}
      >
        <div className={classes.shareButtonsContainer}>
          <FacebookShareButton url={currentUrl}>
            <FacebookIcon size={38} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={currentUrl}>
            <WhatsappIcon size={38} round={true} />
          </WhatsappShareButton>
          <TwitterShareButton url={currentUrl}>
            <TwitterIcon size={38} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={currentUrl}>
            <LinkedinIcon size={38} round={true} />
          </LinkedinShareButton>
          <EmailShareButton url={currentUrl}>
            <EmailIcon size={38} round={true} />
          </EmailShareButton>
          <TelegramShareButton url={currentUrl}>
            <TelegramIcon size={38} round={true} />
          </TelegramShareButton>
        </div>
      </Modal>
      {/* <Modal
        title="Rating Breakdown"
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        {Object.entries(ratings)
          .reverse()
          .map(([key, value], index) => {
            const rating = 5 - index; // Convert "one" to 1, "two" to 2, etc.

            return (
              <div key={rating} style={{ marginBottom: 10 }}>
                <span>{rating} ⭐</span>
                <Progress percent={animatedValues[key]} showInfo />
              </div>
            );
          })}
      </Modal> */}
    </div>
  );
};

export default ItemInfo;
